<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">汇交记录列表</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入宗地名称" class="iw"></el-input>
                <el-select v-model="zdlx" placeholder="选择宗地类型" filterable clearable class="iw">
                    <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name"
                        :value="item.SnowID"></el-option>
                </el-select>
                <el-input v-model="bdcdyh" placeholder="请输入不动产单元号" class="iw"></el-input>
                <el-select v-model="ejdwsel" placeholder="选择二级单位" filterable class="iww" collapse-tags multiple
                    @change="changeEjdwSel" v-show="sysType == '5'">
                    <el-option v-for="item in ejdwSelList" :key="item" :label="item" :value="item"></el-option>
                </el-select>
                <el-select v-model="tjr" placeholder="选择提交人" filterable clearable class="iw" v-show="sysType == '2'">
                    <el-option v-for="item in tjrList" :key="item.SNOWID" :label="item.DISPLAYNAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-select v-model="fzdw" placeholder="选择发证地区" filterable clearable class="iw">
                    <el-option v-for="item in fzdwList" :key="item" :label="item" :value="item"></el-option>
                </el-select>
                <el-select v-model="cgzt" placeholder="成果审核状态" filterable clearable class="iw">
                    <el-option label="待审核" value="0"></el-option>
                    <el-option label="作业单位已确认" value="2"></el-option>
                    <el-option label="油田方已确认" value="3"></el-option>
                    <el-option label="需修改" value="4"></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="warning" @click="exportExcelByZydw"
                    v-show="sysType == '2' && !exportStatus">导出Excel统计表</el-button>
                <el-button type="warning" @click="exportPdfByZydw"
                    v-show="sysType == '2' && !exportStatus">导出pdf扫描件</el-button>
                <el-button type="info" info v-show="sysType == '2' && exportStatus" @click="stopExport">{{
                    fileExportingStr }}</el-button>
                <el-button type="warning" @click="exportExcelByEjdw" v-show="sysType == '5'">导出Excel统计表</el-button>
                <el-button type="warning" @click="exportPdfByEjdw" v-show="sysType == '5'">导出pdf扫描件</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">

                    <el-table-column prop="STATUS" label="审核状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS == '0'" style="color:#909399;">待审核</span>
                            <span v-else-if="scope.row.STATUS == '2'" style="color:#409EFF;">作业单位已确认</span>
                            <span v-else-if="scope.row.STATUS == '3'" style="color:#67C23A;">油田方已确认</span>
                            <span v-else-if="scope.row.STATUS == '4'" style="color:#F56C6C;">需修改</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LandStatus" label="审核失败原因">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS == '4'" style="color:#F56C6C;">{{ scope.row.CONFIRMINFO
                                }}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LandName" label="宗地名称"></el-table-column>
                    <el-table-column prop="LandTypeName" label="宗地类型"></el-table-column>
                    <el-table-column prop="LandStatus" label="地块现状">
                        <template slot-scope="scope">
                            <span v-if="scope.row.LandStatus == '1'">正常</span>
                            <span v-else-if="scope.row.LandStatus == '2'">侵占</span>
                            <span v-else-if="scope.row.LandStatus == '3'">耕地</span>
                            <span v-else-if="scope.row.LandStatus == '4'">无井</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="SYDW" label="使用单位"></el-table-column>
                    <el-table-column prop="ZSBH" label="证书编号"></el-table-column>
                    <el-table-column prop="QLR" label="权利人"></el-table-column>
                    <el-table-column prop="GYQK" label="共有情况"></el-table-column>
                    <el-table-column prop="ZL" label="坐落"></el-table-column>
                    <el-table-column prop="BDCDYH" label="不动产单元号"></el-table-column>
                    <el-table-column prop="QLLX" label="权利类型"></el-table-column>
                    <el-table-column prop="QLXZ" label="权利性质"></el-table-column>
                    <el-table-column prop="YT" label="用途"></el-table-column>
                    <el-table-column prop="MJ" label="证书面积（㎡）">
                        <template slot-scope="scope">
                            <span v-if="scope.row.MJ">{{ scope.row.MJ.toFixed(2) }}</span>
                            <span v-else></span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="TXTMJ" label="实测面积（㎡）">
                        <template slot-scope="scope">
                            <span v-if="scope.row.TXTMJ">{{ scope.row.TXTMJ.toFixed(2) }}</span>
                            <span v-else></span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="SYQX" label="使用期限"></el-table-column>
                    <el-table-column prop="QLQTZK" label="权利其他状况"></el-table-column>
                    <el-table-column prop="FZDW" label="发证地区"></el-table-column>
                    <!-- <el-table-column prop="CAD" label="CAD源文件">
                        <template slot-scope="scope">
                            <span v-if="scope.row.CAD !=''" @click="downloadFile(scope.row.CAD)" style="color:#409EFF;cursor:pointer;">点击下载</span>
                            <span v-else>未上传</span>    
                        </template>
                    </el-table-column>
                    <el-table-column prop="PDF" label="证书扫描件">
                        <template slot-scope="scope">
                            <span v-if="scope.row.PDF !=''" @click="downloadFile(scope.row.PDF)" style="color:#409EFF;cursor:pointer;">点击查看</span>
                            <span v-else>未上传</span>    
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="WorkUnitName" label="作业单位"></el-table-column>
                    <el-table-column prop="SubmitUserName" label="提交人"></el-table-column>

                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C"
                                v-if="scope.row.STATUS != '2' && scope.row.STATUS != '3' && sysType == '4' && scope.row.SubmitUserSnowID == tjr">编辑</el-button>
                            <el-button @click="delFruit(scope.row)" type="text" size="small" style="color:#F56C6C"
                                v-if="scope.row.STATUS != '2' && scope.row.STATUS != '3' && sysType == '4' && scope.row.SubmitUserSnowID == tjr">删除</el-button>
                            <el-button @click="auditingDWItem(scope.row)" type="text" size="small" style="color:#409EFF"
                                v-if="scope.row.STATUS == '0' && sysType == '2'">审核</el-button>
                            <el-button @click="auditingDWItem(scope.row)" type="text" size="small" style="color:#409EFF"
                                v-if="scope.row.STATUS == '4' && sysType == '2'">审核</el-button>
                            <el-button @click="auditingDWItem(scope.row)" type="text" size="small" style="color:#409EFF"
                                v-if="scope.row.STATUS == '2' && sysType == '2'">审核</el-button>
                            <el-button @click="auditingYTItem(scope.row)" type="text" size="small" style="color:#409EFF"
                                v-if="scope.row.STATUS == '2' && sysType == '5'">审核</el-button>
                            <el-button @click="showDetail(scope.row)" type="text" size="small"
                                style="color:#67C23A">查看详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex"
                    :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="fruitModal" width="800px" :close-on-click-modal="false" top="20px">
            <el-form :model="fruitInfo" status-icon :rules="fruitrules" ref="fForm" label-width="220px"
                class="demo-uForm">
                <el-form-item label="宗地名称" prop="landName">
                    <el-input v-model="fruitInfo.landName" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="宗地类型" prop="landTypeSnowID">
                    <el-select v-model="fruitInfo.landTypeSnowID" placeholder="请选择宗地类型" filterable style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name"
                            :value="item.SnowID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地块现状" prop="landStatus">
                    <el-select v-model="fruitInfo.landStatus" placeholder="请选择地块现状" filterable style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option value="1" label="正常"></el-option>
                        <el-option value="2" label="侵占"></el-option>
                        <el-option value="3" label="耕地"></el-option>
                        <el-option value="4" label="无井"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级单位" prop="ejdw">
                    <el-select v-model="fruitInfo.ejdw" placeholder="请选择二级单位" filterable style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="使用单位">
                    <el-input v-model="fruitInfo.sydw" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="不动产证书编号" prop="zsbh">
                    <el-input v-model="fruitInfo.zsbh" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="权利人" prop="qlr">
                    <el-input v-model="fruitInfo.qlr" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="共有情况" prop="gyqk">
                    <el-select v-model="fruitInfo.gyqk" placeholder="请选择共有情况" filterable style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option v-for="item in dicgyqk" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="坐落" prop="zl">
                    <el-input v-model="fruitInfo.zl" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="不动产单元号" prop="bdcdyh">
                    <el-input v-model="fruitInfo.bdcdyh" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="权利类型" prop="qllx">
                    <el-select v-model="fruitInfo.qllx" placeholder="请选择权利类型" filterable style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option v-for="item in dicqllx" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="权利性质" prop="qlxz">
                    <el-select v-model="fruitInfo.qlxz" placeholder="请选择权利性质" filterable style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option v-for="item in dicqlxz" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用途" prop="yt">
                    <el-select v-model="fruitInfo.yt" placeholder="请选择用途" filterable style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option v-for="item in dicyt" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="使用期限">
                    <el-input v-model="fruitInfo.syqx" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="权利其他状况">
                    <el-input v-model="fruitInfo.qlqtzk" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="发证地区" prop="fzdws">
                    <el-cascader v-model="fruitInfo.fzdws" ref="cascaderAddr" :options="options" :props="cityProps"
                        placeholder="请选地区" style="width:100%" :disabled="canSubmitFruit"></el-cascader>
                </el-form-item>
                <el-form-item label="面积（平方米）" prop="mj">
                    <el-input v-model="fruitInfo.mj" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <!-- prop="txt" -->
                <el-form-item label="地块范围坐标文件" prop="txt">
                    <div>
                        <el-upload :disabled="canSubmitFruit" style="display:inline-block;" class="avatar-uploader"
                            action="" :show-file-list="false" :http-request="uploadFunB"
                            :before-upload="beforeAvatarUploadB">
                            <div v-if="txtFileName != ''">
                                <p>{{ txtFileName }}　 </p>　

                            </div>
                            <el-button v-else type="primary" size="mini"
                                :disabled="canSubmitFruit">选择地块范围坐标txt文件</el-button>
                        </el-upload>
                        <p style="color:#409EFF;text-align:left;display:inline-block;margin-left:10px;">
                            坐标面积： <span v-if="txtFileName != ''">{{ txtArea }}㎡ </span> <span v-else>未选择</span>
                        </p>
                    </div>
                    <div>
                        <el-upload :disabled="canSubmitFruit" style="display:inline-block;" class="avatar-uploader"
                            action="" :show-file-list="false" :http-request="uploadFunE"
                            :before-upload="beforeAvatarUploadE">
                            <div v-if="excelFileName != ''">
                                <p>{{ excelFileName }}　 </p>　
                            </div>
                            <el-button v-else type="primary" size="mini"
                                :disabled="canSubmitFruit">选择excel文件转换成txt</el-button>
                        </el-upload>
                        <el-button v-if="excelFileName != ''" type="text" style="color:#E6A23C;"
                            @click="saveExcelToTxt()" :disabled="canSubmitFruit">点击保存{{ txtFileName }}到本地</el-button>
                    </div>
                    <el-button type="danger" @click="deleteFileB()" style="margin-left:0;" size="small"
                        :disabled="canSubmitFruit">清空所有坐标文件</el-button>
                </el-form-item>
                <el-form-item label="文件格式说明">
                    <p style="line-height: 20px;margin-top: 11px;color:#F56C6C;">1、地块范围文件必须为txt格式 2、必须为CGCS2000投影坐标
                        3、坐标点用J1(1为序号)开头，用逗号分割 4、如有多个面用Z隔开 5、可以通过excel转换成txt上传</p>
                    <p><span style="cursor: pointer;color:#E6A23C;" @click="downloadTxtTemplate">点击下载txt文件模板</span><span
                            style="cursor: pointer;color:#E6A23C;margin-left:20px"
                            @click="downloadExcelTemplate">点击下载excel文件模板</span></p>
                </el-form-item>
                <div v-if="showMap">
                    <div id="mapboxB" style="width:100%;height:300px;background-color:#F8F8F8;"></div>
                    <div style="overflow: hidden;margin: 10px 0px;">
                        <el-button type="success" @click="reloadMapLayer" size="small"
                            style="float:right;">刷新当前图层</el-button>
                        <el-checkbox v-model="feaOI" @change="fraOIChange">坐标(txt)文件图形预览</el-checkbox>
                        <el-button type="primary" @click="toTxtFileCenter" size="small" style="margin-left: 20px;">
                            定位到坐标(txt)文件位置</el-button>
                    </div>
                    <p style="width: 100%;overflow: hidden;font-size: 12px;color:#F56C6C;">
                        提交完成后刷新当前图层查看（红色）切片是否正确显示，如果切片未显示或图形不对（切片有延迟，请多刷新几次图层）请联系开发人员。</p>
                </div>
                <el-form-item label="不动产权证书、宗地图扫描件上传" prop="smj">
                    <el-upload :disabled="canSubmitFruit" class="avatar-uploader" action="" :show-file-list="false"
                        :http-request="uploadFunC" :before-upload="beforeAvatarUploadC">
                        <span v-if="smjFileName != ''" style="color:#409EFF;">{{ smjFileName }}</span>
                        <el-button v-else type="primary" size="mini" :disabled="canSubmitFruit">选择文件</el-button>
                    </el-upload>
                    <el-button v-if="smjFileName != ''" type="danger" size="mini" @click="deleteFileC()"
                        :disabled="canSubmitFruit">取消选择</el-button>
                </el-form-item>
                <el-form-item label="CAD源文件上传">
                    <el-upload :disabled="canSubmitFruit" class="avatar-uploader" action="" :show-file-list="false"
                        :http-request="uploadFunD" :before-upload="beforeAvatarUploadD">
                        <span v-if="cadFileName != ''" style="color:#409EFF;">{{ cadFileName }}</span>
                        <el-button v-else type="primary" size="mini" :disabled="canSubmitFruit">选择文件</el-button>
                    </el-upload>
                    <el-button v-if="cadFileName != ''" type="danger" size="mini" @click="deleteFileD()"
                        :disabled="canSubmitFruit">取消选择</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitFruitForm()" :disabled="canSubmitFruit">提交</el-button>
                    <el-button @click="fruitModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="成果详情" :visible.sync="detailModal" width="1400px" :close-on-click-modal="false" top="20px"
            class="detailBox">
            <div class="con">
                <div class="left" :style="{ height: (pdfHeight + 40) + 'px' }">
                    <div class="blockTitle">
                        基础信息
                    </div>
                    <div class="blockCon" v-if="detailModal">
                        <div class="strRow">宗地名称：{{ detailItem.LandName }}</div>
                        <div class="strRow">证书编号：{{ detailItem.ZSBH }}</div>
                        <div class="strRow">不动产单元号：{{ detailItem.BDCDYH }}</div>
                        <div class="strRow strRowh">宗地类型：{{ detailItem.LandTypeName }}</div>
                        <div class="strRow strRowh">面积：{{ detailItem.MJ }}㎡</div>
                        <div class="strRow strRowh">发证地区：{{ detailItem.FZDW }}</div>
                        <div class="strRow strRowh">
                            地块现状：
                            <span v-if="detailItem.LandStatus == '1'">正常</span>
                            <span v-else-if="detailItem.LandStatus == '2'">侵占</span>
                            <span v-else-if="detailItem.LandStatus == '3'">耕地</span>
                            <span v-else-if="detailItem.LandStatus == '4'">无井</span>
                        </div>
                        <div class="strRow strRowh">二级单位：{{ detailItem.EJDW }}</div>
                        <div class="strRow strRowh">使用单位：{{ detailItem.SYDW }}</div>
                        <div class="strRow strRowh">权利人：{{ detailItem.QLR }}</div>
                        <div class="strRow strRowh">共有情况：{{ detailItem.GYQK }}</div>
                        <div class="strRow strRowh">坐落：{{ detailItem.ZL }}</div>
                        <div class="strRow strRowh">用途：{{ detailItem.YT }}</div>
                        <div class="strRow strRowh">权利类型：{{ detailItem.QLLX }}</div>
                        <div class="strRow strRowh">权利性质：{{ detailItem.QLXZ }}</div>
                        <div class="strRow">权利其他状况：{{ detailItem.QLQTZK }}</div>
                        <div>
                            <a :href="txtSrc" target="_blank" v-if="txtSrc != ''" style="margin-right:20px;">查看坐标文件</a>
                            <a :href="pdfSrc" target="_blank" v-if="pdfSrc != ''"
                                style="margin-right:20px;">查看不动产权证书、宗地图扫描件</a>
                            <a :href="cadSrc" target="_blank" v-if="cadSrc != ''"
                                style="margin-right:20px;">查看CAD源文件</a>
                        </div>
                    </div>
                    <div class="blockTitle" v-if="showtdt">
                        现场位置
                    </div>
                    <div class="blockCon" v-if="showtdt">
                        <TianMap ref="tmap" :move="false" :draw="true" :width="olWidth" :height="olHeight"
                            @mapFinish="tianmapFinishA"></TianMap>
                        <div class="imgRow" v-if="taskImgs.length > 0">
                            <el-image v-for="url in taskImgs" :key="url" :src="url" :preview-src-list="[url]" lazy
                                style="width: 100px; height: 100px;margin-left:10px;">
                            </el-image>
                        </div>
                    </div>

                </div>
                <div class="right">
                    <div class="blockTitle" v-if="fallDetail">
                        落宗详情
                    </div>
                    <div class="blockCon" v-if="fallDetail">
                        <div class="strRow strRowh">宗地类型：{{ fallDetail.LZLandTypeName }}</div>
                        <div class="strRow strRowh">行政区：{{ fallDetail.REGION }}</div>
                        <div class="strRow">不动产单元号：{{ fallDetail.BDCDYH }}</div>
                        <div class="strRow strRowh">证书类型：{{ fallDetail.OLDBHTYPE }}</div>
                        <div class="strRow" style="height:1px;"></div>
                        <div class="strRow strRowh">面积：{{ fallDetail.LZMJ }}㎡</div>
                        <div class="strRow strRowh">原面积：{{ fallDetail.OLDMJ }}</div>
                        <div class="strRow"
                            v-if="fallDetail.OLDMJ && fallDetail.OLDMJ != '' && fallDetail.OLDMJ != fallDetail.LZMJ">
                            面积变化原因：{{ fallDetail.CHANGEINFO }}</div>
                        <div class="strRow strRowh">提交单位/人员：{{ fallDetail.WorkUnitName }}/{{ fallDetail.WorkUserName }}
                        </div>
                        <div class="strRow strRowh">提交时间：{{ fallDetail.UpdateTime }}</div>
                    </div>
                    <!-- <div class="blockTitle">
                        证书扫描件
                    </div> -->
                    <!-- <embed :src="pdfSrc" type="application/pdf" width="100%" :height="fallDetail?pdfHeight-150:pdfHeight" /> -->
                    <div class="blockTitle" v-if="showzbt">
                        范围坐标
                    </div>
                    <div class="blockCon" v-if="showzbt">
                        <div id="mapboxC" style="width:100%;height:600px;background-color:#F8F8F8;"></div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog :title="exportName" :visible.sync="exportModal" width="1000px" :close-on-click-modal="false"
            top="50px">
            <el-form status-icon label-width="100px" class="demo-uForm">
                <el-form-item label="选择二级单位">
                    <el-checkbox-group v-model="checkEjdws">
                        <el-checkbox v-for="item in ejdwsel" :label="item" :key="item" class="ejdwCheckItem">{{ item
                        }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item>
                    <p v-if="exportFileType == '2'" style="font-size:12px;color:#F56C6C;width:100%;float:left;">
                        提示：导出pdf时可能会因为文件数量过多导致导出时间过慢请您耐心等待</p>
                    <el-button type="primary" @click="checkExportUnits">全选/全不选</el-button>
                    <el-button @click="exportModal = false" class="fr">取消</el-button>
                    <el-button :type="exportStatus ? 'danger' : 'warning'" @click="submitExport" class="fr">{{
                        exportStatus ?
                        '文件导出中，点击停止' : '开始导出' }}</el-button>

                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="auditingTitle" :visible.sync="auditingModal" width="800px" :close-on-click-modal="false">
            <el-form :model="auditingInfo" status-icon :rules="auditingrules" ref="aForm" label-width="120px"
                class="demo-uForm">
                <el-form-item label="审核结果">
                    <el-radio v-model="auditingInfo.confirmResult" label="0">通过</el-radio>
                    <el-radio v-model="auditingInfo.confirmResult" label="1">不通过</el-radio>
                </el-form-item>
                <el-form-item label="不通过原因" prop="confirmInfo" v-show="auditingInfo.confirmResult == '1'">
                    <el-input v-model="auditingInfo.confirmInfo" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="sendAuditing()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script type="text/javascript" src="../../public/statics/js/proj4.js"></script>
<script>
var h = document.body.clientHeight
import TianMap from "../../components/OpenLayers"

import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import ImageWMS from "ol/source/ImageWMS";
import OSM from 'ol/source/OSM';
import Projection from 'ol/proj/Projection';
import {Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer ,Image as ImageLayer} from "ol/layer";
import WMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo';
import { Fill, Stroke, Circle, Style } from "ol/style";
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import {register} from 'ol/proj/proj4';
import * as olProj from 'ol/proj';
import * as XLSX from 'xlsx';
import * as turf from '@turf/turf';
export default {
  name: "RegionManage",
  components:{
    TianMap,
  },
  data() {
    var landName=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入宗地名称"));
      } else {
        callback();
      }
    };

    var landTypeSnowID=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择宗地类型"));
      } else {
        callback();
      }
    };

    var landStatus = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择地块现状"));
      } else {
        callback();
      }
    };

    var ejdw = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择二级单位"));
      } else {
        callback();
      }
    };

    var sydw=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入使用单位"));
      } else {
        callback();
      }
    };

    var qlr = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利人"));
      } else {
        callback();
      }
    };

    var gyqk = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入共有情况"));
      } else {
        callback();
      }
    };

    var zl = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入坐落"));
      } else {
        callback();
      }
    };

    var bdcdyh = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入不动产单元号"));
      } else {
        callback();
      }
    };

    var qllx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利类型"));
      } else {
        callback();
      }
    };

    var qlxz = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利性质"));
      } else {
        callback();
      }
    };

    var yt = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入用途"));
      } else {
        callback();
      }
    };
    
    var mJ_M=(rule, value, callback) => {
        if(value != "" && typeof value != 'number' && isNaN(value)){
            callback(new Error('面积只能是数字'));
        }else if(value<=0){
            callback(new Error('面积必须大于0'));
        }else {
          callback();
        }
    };

    var syqx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入使用期限"));
      } else {
        callback();
      }
    };

    var fzdws= (rule, value, callback) => {
      if (value.length!=3) {
        callback(new Error("请选择发证地区"));
      } else {
        callback();
      }
    };

    var smj=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请上传不动产权证书、宗地图扫描件"));
      } else {
        callback();
      }
    };

    var txt=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请上传地块范围坐标文件"));
      } else {
        callback();
      }
    };

    var zsbh=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入土地证（不动产证）号"));
      } else {
        callback();
      }
    };

    var confirmInfo=(rule, value, callback) => {
      if (this.auditingInfo.confirmResult=="1" && value === "" || value == null) {
        callback(new Error("请输入不通过原因"));
      } else {
        callback();
      }
    };

    return {
        usersnowid:"",
        sysType:"",
        zydwid:"",
        userName:"",
        bdcdyh:"",

        canDo:true,
        showPagination:false,
        ejdwstr:"",
        ejdwsel:[],
        ejdwSelList:[],
        fzdw:"",
        name:"",
        tjr:"",
        tjrList:[],
        cgzt:"",
        zdlx:"",

        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        dicgyqk:[],
        dicqllx:[],
        dicqlxz:[],
        dicyt:[],

        zdlxList:[],
        doTypeStr:"",
        doType:"",
        ejdwList:[],
        fzdwList:[],
        fruitModal:false,
        fruitInfo:{},
        fruitrules: {
            landName: [{ validator: landName, trigger: "blur" }],
            landTypeSnowID: [{ validator: landTypeSnowID, trigger: "blur" }],
            landStatus: [{ validator: landStatus, trigger: "blur" }],
            ejdw: [{ validator: ejdw, trigger: "blur" }],
            sydw: [{ validator: sydw, trigger: "blur" }],
            zsbh: [{ validator: zsbh, trigger: "blur" }],
            qlr: [{ validator: qlr, trigger: "blur" }],
            gyqk: [{ validator: gyqk, trigger: "blur" }],
            zl: [{ validator: zl, trigger: "blur" }],
            bdcdyh: [{ validator: bdcdyh, trigger: "blur" }],
            qllx: [{ validator: qllx, trigger: "blur" }],
            qlxz: [{ validator: qlxz, trigger: "blur" }],
            yt: [{ validator: yt, trigger: "blur" }],
            mj: [{ validator: mJ_M, trigger: "blur" }],
            syqx: [{ validator: syqx, trigger: "blur" }],
            fzdws: [{ validator: fzdws, trigger: "blur" }],
            smj:[{ validator: smj, trigger: "blur" }],
            txt:[{ validator: txt, trigger: "blur" }],
        },
        friuitLandName:"",


        txtArea:0,
        txtFile:null,
        txtFileSrc:"",
        txtFileId:"",
        txtFileName:"",
        chooseTxtFile:false,
        smjFile:null,
        smjFileSrc:"",
        smjFileId:"",
        smjFileName:"",
        chooseSmjFile:false,
        cadFile:null,
        cadFileSrc:"",
        cadFileId:"",
        cadFileName:"",
        chooseCadFile:false,

        excelFile:null,
        excelFileSrc:"",
        excelFileName:"",
        excelzbstr:"",

        detailItem:null,
        detailModal:false,
        pdfHeight:"600px",
        pdfSrc:"",
        txtSrc:"",
        cadSrc:"",
        showtdt:false,
        showzbt:false,
        taskImgs:[],

        auditingModal:false,
        auditingTitle:"",
        auditingInfo:{
            exchangeResultSnowID:"",
            confirmResult:"0",
            confirmInfo:"",
        },
        auditingrules:{
            confirmInfo: [{ validator: confirmInfo, trigger: "blur" }],
        },

        options:[],
        cityProps:{
            value:'name',   
            label:'name',  
            children: 'children'   
        },

        tianmap:null,
        olWidth:680,
        olHeight:300,

        fallDetail:{},


        canSubmitFruit:false,
        
        drawSource:null,
        drawVector:null,
        map:null,
        view:null,
        showMap:false,
        wsmSource:null,
        feaOI:true,
        mapCenter:null,


        exportModal:false,
        exportName:"",
        exportType:"",
        exportFileType:"",
        checkUnits:[],
        checkEjdws:[],
        exportTimer:"",
        exportStatus:false,
        fileExportingStr:"",
    };
  },

  mounted() {
    this.options=this.comjs.citys
    this.pdfHeight = (h - 170)+"px"
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.landName
        this.ejdw=getQueryData.ejdw
        this.fzdw=getQueryData.fzdw
    }
    this.loadItem=null
    this.getDisList()
    this.getEjdwList()
    this.getFzdwList()
    this.getZdlx()
    this.userName=this.$store.state.name
    this.sysType=this.$store.state.sysType  
    if(this.sysType=="2"){
        this.getzzdwA()
    }else if(this.sysType=="4"){
        this.getzzdwB()
        this.tjr=this.$store.state.snowid
    }else if(this.sysType=="5"){
        this.getejdwqx()
    }
  },
  methods: {
    getDisList(){
        this.$http.post("Dic/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list=response.data.DATA
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        if(d.Type==1){
                            this.dicgyqk.push(d.Name)
                        }else if(d.Type==2){
                            this.dicqllx.push(d.Name)
                        }else if(d.Type==3){
                            this.dicqlxz.push(d.Name)
                        }else if(d.Type==4){
                            this.dicyt.push(d.Name)
                        }
                    }
                }
            })
    },
    tianmapFinishA(){
        this.tianmap=this.$refs.tmap.map
    },
    changeEjdwSel(e){
        var str=""
        var list=[]
        if(e.length==0){
            this.ejdwsel=this.ejdwSelList
            list=this.ejdwSelList
        }else {
            list =e
        }
        for(var i=0;i<list.length;i++){
            if(i==0){
                str=list[i]
            }else{
                str=str+","+list[i]
            }
        }
        this.ejdwstr=str
    },
    getZdlx(){
        this.canDo=false
        this.$http.post("LZLandType/GetDataList", {})
            .then((response) => {
                this.zdlxList=response.data.DATA
            })
    },
    getZdlxNameById(id){
        var name=""
        for(var i=0;i<this.zdlxList.length;i++){
            if(this.zdlxList[i].SnowID== id){
                name=this.zdlxList[i].Name
            }
        }
        return name
    },
    getzzdwA(){
        this.canDo=false
        this.$http.post("WorkUnit/GetDataList", {name:this.$store.state.name})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.zydwid=response.data.DATA[0].SNOWID
                    this.canDo=true
                    this.getFruitUserList(this.zydwid)
                    this.getPostList()
                }else{

                    this.$confirm('作业单位获取失败，请重新登录', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                       this.logout()
                    }).catch(() => {
                        this.logout()
                    });
                    setTimeout(() => {
                        this.logout()
                    }, 10000);
                }
            })
    },
    getFruitUserList(id){
        this.$http.post("ResultUser/GetDataList", {WorkUnitSnowID:id})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.tjrList=response.data.DATA
                }
            })
    },
    getzzdwB(){
        this.canDo=false
        this.$http.post("ResultUser/GetDataList", {snowID:this.$store.state.snowid})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.zydwid=response.data.DATA[0].WorkUnitSnowID
                    this.canDo=true
                    this.getPostList()
                }else{
                    this.$confirm('作业单位获取失败，请重新登录', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                       this.logout()
                    }).catch(() => {
                        this.logout()
                    });
                    setTimeout(() => {
                        this.logout()
                    }, 10000);
                }
            })
    },
    getejdwqx(){
        this.canDo=false
        this.$http.post("OilUser/GetOilUserEJDW", {oilUserSnowID:this.$store.state.snowid})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    var ejdw=response.data.DATA[0].EJDWSTR
                    if(ejdw!=""){
                        this.ejdwstr=ejdw
                        this.ejdwsel=ejdw.split(",")
                        this.ejdwSelList=ejdw.split(",")
                    } 
                    this.canDo=true
                }
                this.getPostList()
            })
    },
    downloadFile(src){
        window.open(src)
    },
    getFzdwList(){
        this.$http.post("ExchangeResult/GetDistrictList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.fzdwList=response.data.DATA
                }
            })
    },
    getEjdwList(){
        this.$http.get("Land/GetEJDWList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ejdwList=response.data.DATA
                }
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            ejdw:this.ejdwstr,
            fzdw:this.fzdw,
            landName:this.name,
            submitUserSnowID:this.tjr,
            status:this.cgzt,
            landTypeSnowID:this.zdlx,
            workUnitSnowID:this.zydwid,
            LZBDCDYH:this.bdcdyh,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("ExchangeResult/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },


    toTxtFileCenter(){
        if(this.mapCenter){
            this.view.setCenter(this.mapCenter)
        }else{
            this.$message.error("请先上传坐标文件")
        }
    },
    fraOIChange(){
        this.drawVector.setVisible(this.feaOI)
    },
    reloadMapLayer(){
        if(this.map&&this.wsmSource){
            this.wsmSource.refresh();
            this.$message.success("刷新成功")
        }
    },

    loadMapDoms(arr,mapId){
        if(this.map){
            this.map.getLayers().forEach(function(layer) {
                layer.setMap(null);
            });
            this.map.getControls().forEach((control)=> {
                this.map.removeControl(control);
            });
        
            this.map.getInteractions().forEach((interaction)=> {
                this.map.removeInteraction(interaction);
            });
        
            this.map.setTarget(null);
            this.map = null;
        }
        proj4.defs(
                "EPSG:4529",
                "+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=41500000 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs"
            )
            window.ol.proj.proj4.register(proj4);
            register(proj4)
            var center_lonlat = [123.049,41.928];
            this.view=new View({
                center: [121.9939703484, 41.1335237089], 
                projection: 'EPSG:4326',
                zoom: 10, 
            })
            this.wsmSource=new ImageWMS({
                url: "https://file.17fbfb.com/?map=/etc/mapserver/wms.map&service=wms?",
                params: {
                    MAP:'/etc/mapserver/wms.map',
                    LAYERS:'zongdi',
                    //CRS:'epsp:4529',
                    //BBOX:'122,41,123.2,42',
                    transparent: "true"
                },
                serverType:'mapserver'
            })
            this.map = new Map({
                zIndex:1,
                target: mapId,
                layers: [
                    new ImageLayer({
						name:"地块", 
						source: this.wsmSource,
					}),
				],
                view: this.view,
            });
            this.map.on('singleclick', function(event) {
                const coordinate = event.coordinate; 
            })
            this.map.updateSize()
            this.drawSource = new VectorSource({wrapX: false});
            this.drawVector = new VectorLayer({
                source: this.drawSource,
                zIndex:100,
                style: new Style({
                    fill: new Fill({
                        color: "rgba(64,158,255,0.2)"
                    }),
                    stroke: new Stroke({
                        color: "#409EFF",
                        width: 2
                    }),
                    image: new Circle({
                        radius: 5,
                        fill: new Fill({
                            color: "rgba(64,158,255,1)"
                        })
                    })
                }),
            });
            this.map.addLayer(this.drawVector)
            var arrItem=this.transformXY(arr)
            if(arrItem.a.length==1){
                var areaGeom= new window.ol.geom.Polygon(arrItem.a)
                this.txtArea=areaGeom.getArea()
            }else{
                var independentPolygons=this.getIndependentPolygons(arrItem.a)
                var sarea=0
                for(var i=0;i<independentPolygons.length;i++){
                    var inditem= independentPolygons[i]
                    let indGeom=new window.ol.geom.Polygon(inditem)
                    console.log(indGeom.getArea())
                    sarea+=indGeom.getArea()
                }
                this.txtArea=sarea
            }

            var newZbs=[]
            for(var z=0;z<arrItem.b.length;z++){
                var zbz=arrItem.b[z]
                var nzbz=[]
                for(var k=0;k<zbz.length;k++){
                    let zbr=window.ol.proj.transform(zbz[k], 'EPSG:3857', 'EPSG:4326');
                    nzbz.push(zbr)
                }
                newZbs.push(nzbz)
            }

            var feaGeom= new Polygon(newZbs)
            var ft = new Feature({
                geometry: feaGeom,
            });
            this.drawSource.addFeature(ft)
            this.mapCenter=newZbs[0][0]
            this.view.setCenter(newZbs[0][0])
            this.view.setZoom(15)
    },
    getIndependentPolygons(arrlist){
        var polygonList=[]
        var outPolygon=turf.polygon([arrlist[0]])
        var onePolygon=[arrlist[0]]
        for(var i=1;i<arrlist.length;i++){
            var pitem=arrlist[i]
            var titem=turf.polygon([pitem])
            let f=turf.booleanContains(outPolygon,titem)
            if(f){
                onePolygon.push(pitem)
                if((i+1)==arrlist.length){
                    polygonList.push(onePolygon)
                }
            }else{
                polygonList.push(onePolygon)
                if((i+1)==arrlist.length){
                    polygonList.push([pitem])
                }else{
                    outPolygon=turf.polygon([pitem])
                    onePolygon=[pitem]
                }
            }
        }
        return polygonList
    },
    transformXY(olist){
        var arr={
            a:[],
            b:[],
        }
        for(var k=0;k<olist.length;k++){
            var list =olist[k]
            var l={a:[],b:[],}
            for(var i=0;i<list.length;i++){
                var arritem=[list[i][1],list[i][0]]
                l.a.push(arritem)
                var lonlat_3857 = olProj.transform(arritem, 'EPSG:4529','EPSG:3857');
                l.b.push(lonlat_3857)
            }
            arr.a.push(l.a)
            arr.b.push(l.b)
        }
        
        return arr
    },

    resetMapDoms(){
        this.showMap=false
        if(this.map){
            this.map.getLayers().forEach(function(layer) {
                layer.setMap(null);
            });
            this.map.getControls().forEach((control)=> {
                this.map.removeControl(control);
            });
        
            this.map.getInteractions().forEach((interaction)=> {
                this.map.removeInteraction(interaction);
            });
        
            this.map.setTarget(null);
            this.map = null;
        }
    },

    saveWmsDatas(info){
        var params={
            snowid:info.SnowID,
            tdzh:info.zsbh,
            zl:info.zl,
            dh:info.bdcdyh,
            syqlx:info.qllx,
            fzmc:info.qlr,
            syqlx:info.qllx,
            fzmj:parseFloat(info.mj),
            scmj:parseFloat(info.txtMJ),
        }
        if(this.chooseTxtFile && this.txtFile){
            var fd = new FormData();
            fd.append("file", this.txtFile);
            //上传文件
            this.$wmsUpload.post("gdata/shp/create/", fd)
            .then((response) => {
                var oid=parseInt(response.data.objectid)
                params.objectid=oid
                var paramStr=oid+"?snowid="+info.SnowID+"&tdzh="+info.zsbh+"&zl="+info.zl+"&dh="+info.bdcdyh+"&syqlx="+info.qllx+"&fzmc="+info.qlr+"&syqlx="+info.qllx+"&fzmj="+info.mj+"&scmj="+info.txtMJ
                //更新当前数据
                this.$wmsAxios.post("gdata/shp/update/"+paramStr, params)
                //查询数据
                this.$wmsAxios.get("gdata/shp/querysnowid/"+info.SnowID, {})
                .then((response) => {
                    if( typeof response.data === 'string'){
                        var qd=JSON.parse(response.data)
                        var fs=qd.features
                        for(var i=0;i<fs.length;i++){
                            if(fs[i].properties.OBJECTID != oid && fs[i].properties.SNOWID && fs[i].properties.SNOWID!=""){
                                var udata={
                                    objectid:fs[i].properties.OBJECTID,
                                    snowid:"",
                                }
                                //更新归档数据
                                this.$wmsAxios.post("gdata/shp/update/"+fs[i].properties.OBJECTID+"?snowid=0", udata)
                            }
                        }
                    }
                    
                })  

            })  
        }else{
            //查询数据
            this.$wmsAxios.get("gdata/shp/querysnowid/"+info.SnowID, {})
            .then((response) => {
                if( typeof response.data === 'string'){
                    var qd=JSON.parse(response.data)
                    var fs=qd.features
                    var oidd=0
                    for(var i=0;i<fs.length;i++){
                        if(fs[i].properties.OBJECTID > oidd){
                            oidd=fs[i].properties.OBJECTID
                        }
                    }
                    if(oidd !=0){
                        params.objectid=oidd
                        var paramStr=oidd+"?snowid="+info.SnowID+"&tdzh="+info.zsbh+"&zl="+info.zl+"&dh="+info.bdcdyh+"&syqlx="+info.qllx+"&fzmc="+info.qlr+"&syqlx="+info.qllx+"&fzmj="+info.mj+"&scmj="+info.txtMJ
                        //更新数据
                        this.$wmsAxios.post("gdata/shp/update/"+paramStr, params)
                    }
                }
                
            })  
        }
        
    },

    editPost(item){
        this.resetMapDoms()
        this.canSubmitFruit=false
        this.excelFile=null
        this.excelFileSrc=""
        this.excelFileName=""
        this.excelzbstr=""

        this.txtFile=null
        this.txtFileSrc=""
        this.txtFileId=""
        this.txtFileName=""
        this.chooseTxtFile=false
        this.txtArea=0

        this.smjFile=null
        this.smjFileSrc=""
        this.smjFileId=""
        this.smjFileName=""
        this.chooseSmjFile=false

        this.cadFile=null
        this.cadFileSrc=""
        this.cadFileId=""
        this.cadFileName=""
        this.chooseCadFile=false

        this.friuitLandName=item.DISPLAYNAME
        this.doTypeStr=item.LandName+"成果汇交登记"

        var  smj=""
        var  txt=""
        for(var i=0;i<item.Files.length;i++){
            var path=this.comjs.replaceFileSrc(item.Files[i].Path)
            if(item.Files[i].ImageType ==2){
                smj="1"
                this.smjFileName="不动产权证书、宗地图描件.pdf"
                this.smjFileSrc=path
                this.smjFileId=item.Files[i].SnowID
            }else if(item.Files[i].ImageType ==3){
                this.cadFileName="CAD源文件.dwg"
                this.cadFileSrc=path
                this.cadFileId=item.Files[i].SnowID
            }else if(item.Files[i].ImageType ==7){
                txt="1"
                this.txtFileName="坐标范围文件.txt"
                this.txtFileSrc=path
                this.txtFileId=item.Files[i].SnowID
                this.getTxtFileContent(path,'mapboxB')
            }
        }
        var fzdws=[]
        if(item.FZDW.indexOf("/")!= -1){
            fzdws=item.FZDW.split("/")
        }
        this.fruitInfo={
            SnowID:item.SnowID,
            lzSnowID:item.LZSnowID,
            taskSnowID:item.TaskSnowID,
            landName:item.LandName,
            landTypeSnowID:item.LandTypeSnowID,
            //landSnowID:item.LandSnowID,
            landStatus:item.LandStatus,
            submitUserSnowID:this.$store.state.snowid,
            ejdw:item.EJDW,
            sydw:item.SYDW,
            zsbh:item.ZSBH,
            qlr:item.QLR,
            gyqk:item.GYQK,
            zl:item.ZL,
            bdcdyh:item.BDCDYH,
            qllx:item.QLLX,
            qlxz:item.QLXZ,
            yt:item.YT,
            mj:item.MJ,
            txtMJ:item.TxtMJ,
            syqx:item.SYQX,
            qlqtzk:item.QLQTZK,
            fzdw:item.FZDW,
            fzdws:fzdws,
            smj:smj,
            txt:txt,
        }

        this.fruitModal=true
    },

    getTxtFileContent(src,mapId){
        //http://localhost:8080/statics/lib/210181107210GB00027txt (11).txt
        //src
        // this.$http.get("file/GetContent?fileURL="+src, {})
        //this.$getFileAxios.get(src, {})
        this.$http.get("file/GetContent?fileURL="+src, {})
            .then((response) => {
               var arr=this.getZbByFileStr(response.data);
                if(arr.length>0){
                    this.showMap=true
                    setTimeout(()=>{
                        this.loadMapDoms(arr,mapId)
                    },500)
                }else{
                    this.$message.error('文件坐标格式有误，请重新上传')
                    this.txtFile=null
                    this.txtFileSrc=""
                    this.txtFileId=""
                    this.txtFileName=""
                    this.chooseTxtFile=false
                    this.txtArea=0
                    this.fruitInfo.txt=""
                }
            })
    },
    submitFruitForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                if (valid) {
                    delete  this.fruitInfo.smj
                    delete  this.fruitInfo.txt
                    if(this.fruitInfo.fzdws.length==3){
                        this.fruitInfo.fzdw=this.fruitInfo.fzdws[0]+"/"+this.fruitInfo.fzdws[1]+"/"+this.fruitInfo.fzdws[2]
                    }
                    if(this.chooseTxtFile){
                        this.fruitInfo.txtMJ=this.txtArea
                    }
                    if(this.txtArea<=0){
                        this.$message.error("点位坐标数据有误，请检查后再上传")
                    }else{
                        if(this.fruitInfo.mj != this.txtArea){
                            this.$confirm('坐标文件面积与证书面积不一致，是否继续提交？', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                this.sendFEdit()
                            }).catch(() => {
                                //
                            });
                        }
                    }
                    
                    
                } else {
                    return false;
                }
            });
        }
    },
    sendFEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/UpdateData", this.fruitInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.getPostList()
                    if(this.chooseTxtFile){
                        this.addImgB(this.fruitInfo.SnowID)
                    }
                    if(this.chooseSmjFile){
                        this.addImgC(this.fruitInfo.SnowID)
                    }
                    if(this.chooseCadFile){
                        this.addImgD(this.fruitInfo.SnowID)
                    }
                    this.saveWmsDatas(this.fruitInfo)
                    this.$confirm('是否留在本页面查看宗地图形，确保切片数据正确！', '提交完成', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.wsmSource.refresh();
                        this.canSubmitFruit=true;
                    }).catch(() => {
                        this.fruitModal=false
                    });
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    delFruit(item){
        if(this.canDo){
            this.$confirm('确定删除'+item.LandName+'的成果汇交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDelFruit(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDelFruit(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.deleteWmsData(id)
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    deleteWmsData(snowid){
        this.$wmsAxios.get("gdata/shp/querysnowid/"+snowid, {})
        .then((response) => {
            if( typeof response.data === 'string'){
                var qd=JSON.parse(response.data)
                var fs=qd.features
                for(var i=0;i<fs.length;i++){
                    if(fs[i].properties.SNOWID && fs[i].properties.SNOWID!=""){
                        var udata={
                            objectid:fs[i].properties.OBJECTID,
                            snowid:"",
                        }
                        //更新归档数据
                        this.$wmsAxios.post("gdata/shp/update/"+fs[i].properties.OBJECTID+"?snowid=0", udata)
                    }
                }
            }
            
        }) 
    },


    beforeAvatarUploadC(file) {
        const isJPG = file.type === 'application/pdf';
        if (!isJPG) {
        this.$message.error('证书扫描件只能是 PDF  格式!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.smjFileSrc = _URL.createObjectURL(file);
            this.chooseSmjFile=true
            this.smjFile = file
            this.smjFileName=file.name
            this.fruitInfo.smj="1"
        }
        return isJPG;
    },
    uploadFunC(file){
        //console.log(file)
    },
    beforeAvatarUploadD(file) {
        var  isJPG =false;
        if (file.name.indexOf(".")== -1) {
            this.$message.error('CAD源文件件只能是 DWG  格式!');
        }else{
            if(file.name.split(".")[1] !="dwg" && file.name.split(".")[1] !="DWG"){
                this.$message.error('CAD源文件件只能是 DWG  格式!');
            }else{
                isJPG=true
                var _URL = window.URL || window.webkitURL;
                this.cadFileSrc = _URL.createObjectURL(file);
                this.chooseCadFile=true
                this.cadFile = file
                this.cadFileName=file.name
            }
        }
        return isJPG;
    },
    uploadFunD(file){
        //console.log(file)
    },
    beforeAvatarUploadB(file) {
        const isJPG = file.type === 'text/plain';
        if (!isJPG) {
        this.$message.error('坐标范围文件只能是 TXT  格式!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.txtFileSrc = _URL.createObjectURL(file);
            this.chooseTxtFile=true
            this.txtFile = file
            this.txtFileName=file.name
            this.fruitInfo.txt="1"
            this.excelFile=null
            this.excelFileSrc=""
            this.excelFileName=""
            this.excelzbstr=""
        }
        return isJPG;
    },
    uploadFunB(file){
        this.txtArea=0
        //计算面积
        this.readFile(file.file,(data)=>{
            var arr=this.getZbByFileStr(data);
            if(arr.length>0){
                this.showMap=true
                setTimeout(()=>{
                    this.loadMapDoms(arr,"mapboxB")
                },500)
            }else{
                this.$message.error('文件坐标格式有误，请重新上传')
                this.txtFile=null
                this.txtFileSrc=""
                this.txtFileId=""
                this.txtFileName=""
                this.chooseTxtFile=false
                this.txtArea=0
                this.fruitInfo.txt=""

                this.excelFile=null
                this.excelFileSrc=""
                this.excelFileName=""
                this.excelzbstr=""
            }
        },()=>{
            this.$message.error('解析文件出错，请重新上传')
            this.txtFile=null
            this.txtFileSrc=""
            this.txtFileId=""
            this.txtFileName=""
            this.chooseTxtFile=false
            this.txtArea=0
            this.fruitInfo.txt=""

            this.excelFile=null
            this.excelFileSrc=""
            this.excelFileName=""
            this.excelzbstr=""
        })
    },
    readFile(file,sc,ec) {
        //支持chrome IE10
        if (window.FileReader) {
            var reader = new FileReader();
            reader.onload = function() {
                sc(this.result);
            };
            reader.readAsText(file);
        }
        //支持IE 7 8 9 10
        else if (typeof window.ActiveXObject != 'undefined'){
            var xmlDoc;
            xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
            xmlDoc.async = false;
            xmlDoc.load(input.value);
            sc(xmlDoc);
        }
        //支持FF
        else if (document.implementation && document.implementation.createDocument) {
            var xmlDoc;
            xmlDoc = document.implementation.createDocument("", "", null);
            xmlDoc.async = false;
            xmlDoc.load(input.value);
            sc(xmlDoc);
        } else {
            ec();
        }
    },
    getZbByFileStr(str){
        var arrs=[]
        var zbstr=str.replace(/\s+/g, '')
        var zbs=[]
        if(zbstr.indexOf("Z")!= -1){
            zbs= zbstr.split("Z")
        }else{
            zbs.push(zbstr)
        }

        for(var i=0;i<zbs.length;i++){
            var mian=zbs[i]
            var mianArr=[]
            if(mian.indexOf("J")!=-1){
                var dianArr=mian.split("J")
                for(var j=0;j<dianArr.length;j++){
                    var dian=dianArr[j]
                    var dianDatas=[]
                    if(dian!=""&&dian.indexOf(",")!=-1){
                        var diansZbs=dian.split(",")
                        for(var k=0;k<diansZbs.length;k++){
                            var dianZbItem=diansZbs[k]
                            if(dianZbItem.length>=7 || dianZbItem.indexOf(".")!=-1){
                                dianDatas.push(parseFloat(dianZbItem))
                            }
                        }
                        if(dianDatas.length==2){
                            mianArr.push(dianDatas)
                        }
                    } 
                }
            }
            if(mianArr.length>=4){
                if(mianArr[0][0] != mianArr[mianArr.length-1][0] || mianArr[0][1] != mianArr[mianArr.length-1][1]){
                    mianArr.push(mianArr[0])
                }
                arrs.push(mianArr)
            }
        }
        return arrs
    },
    beforeAvatarUploadE(file) {
        var  isJPG =false;
        if (file.name.indexOf(".")== -1) {
            this.$message.error('只能上传excel文件!');
        }else{
            if(file.name.split(".")[1] !="xlsx" && file.name.split(".")[1] !="xls"){
                this.$message.error('只能上传excel文件!');
            }else{
                isJPG=true
                var _URL = window.URL || window.webkitURL;
                this.excelFileSrc = _URL.createObjectURL(file);
                this.excelFile = file
                this.excelFileName=file.name
                this.excelzbstr=""
            }
        }
        return isJPG;
    },
    uploadFunE(file){
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            var excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            this.setZBStrByExcel(excelData)
        };
        reader.readAsArrayBuffer(file.file);
    },
    setZBStrByExcel(excelData){
        var str=""
        var jList=[]
        for(var i=0;i<excelData.length;i++){
            var d=excelData[i]
            if(d.length>=4 && typeof d[1] ==='string' && d[1].indexOf("J")!= -1){
                jList.push(d)
            }
        }
        var arrList=[]
        var arrItem=[]
        for(var j=0;j<jList.length;j++){
            var r=jList[j]
            var p=jList[j+1]
            if((p!=undefined && p[0] - r[0]) == 1 ){
                arrItem.push(r)
            }else if(p!=undefined && p[0] == r[0]){
                continue; 
            }else{
                arrItem.push(r)
                if(arrItem.length>1){
                    if((j+1) == jList.length-1){
                        arrItem.push(p)
                        arrList.push(arrItem)
                        break;
                    }else{
                        arrList.push(arrItem)
                    }
                    
                }
                arrItem=[]
            }
        }

        for(var k=0;k<arrList.length;k++){
            var m=arrList[k]
            if(k>0){
                str+="Z"+'\n'
            }
            for(var o=0;o<m.length;o++){
                var dian=m[o]
                str+=dian[1]+","+dian[dian.length-2]+","+dian[dian.length-1]+'\n'
            }
        }
        
        this.excelzbstr=str
        this.txtFileName=this.excelFileName.split(".")[0]+".txt"
        var blob = new Blob([str], { type: 'text/plain' });
        this.txtFile= new File([blob], this.txtFileName, {
            type: 'text/plain',
            lastModified: new Date().getTime(), 
        });
        
        this.fruitInfo.txt="1"
        this.chooseTxtFile=true
        var file={file:this.txtFile}
        this.uploadFunB(file)
    },
    saveExcelToTxt(){
        var blob = new Blob([this.excelzbstr], { type: 'text/plain' });
        var url = URL.createObjectURL(blob);
        var downloadLink = document.createElement("a");
        
        downloadLink.href = url;
        downloadLink.download = this.txtFileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
    },


    deleteFileB(){
        this.excelFile=null
        this.excelFileSrc=""
        this.excelFileName=""
        this.excelzbstr=""

        this.txtFile =null
        this.txtFileSrc=""
        this.txtFileName=""
        this.chooseTxtFile=false
        this.fruitInfo.txt=""
        this.txtArea=0
        this.showMap=false
        // if( this.txtFileId!=""){
        //     this.sendDelFile(this.txtFileId)
        // }
    },
    deleteFileC(){
        this.smjFile =null
        this.smjFileSrc=""
        this.smjFileName=""
        this.chooseSmjFile=false
        this.fruitInfo.smj=""

        if( this.smjFileId!=""){
            this.sendDelFile(this.smjFileId)
        }
    },
    deleteFileD(){
        this.cadFile =null
        this.cadFileSrc=""
        this.cadFileName=""
        this.chooseCadFile=false
        if( this.cadFileId!=""){
            this.sendDelFile(this.cadFileId)
        }
    },
    sendDelFile(id){
        this.$http.post("File/DeleteFile", {SnowID:id})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },

    addImgB(id){
        var fd = new FormData();
        fd.append("file", this.txtFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=7&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },
    addImgC(id){
        var fd = new FormData();
        fd.append("file", this.smjFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=2&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },
    addImgD(id){
        var fd = new FormData();
        fd.append("file", this.cadFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=3&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },



    auditingDWItem(item){
        this.auditingTitle=item.LandName+"作业单位审核"
        this.doType="1"
        this.auditingInfo={
            exchangeResultSnowID:item.SnowID,
            confirmResult:"0",
            confirmInfo:"",
        }
        this.auditingModal=true
    },
    auditingYTItem(item){
        this.auditingTitle=item.LandName+"油田方审核"
        this.doType="2"
        this.auditingInfo={
            exchangeResultSnowID:item.SnowID,
            confirmResult:"0",
            confirmInfo:"",
        }
        this.auditingModal=true
    },

    sendAuditing(){
        if(this.canDo) {
            this.$refs.aForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendDWAuditing()
                    }else if(this.doType=="2"){
                        this.sendYTAuditing()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    sendDWAuditing(){
        var ly=""
        if(this.auditingInfo.confirmResult=="1"){
            ly=this.auditingInfo.confirmInfo
        }
        var params={
            exchangeResultSnowID: this.auditingInfo.exchangeResultSnowID,
            confirmResult: this.auditingInfo.confirmResult,
            confirmInfo:ly,
        }
        this.$http.post("ExchangeResult/WorkUnitConfirm", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("审核已提交")
                    this.auditingModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true 
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }  
             });
    },
    sendYTAuditing(){
        var ly=""
        if(this.auditingInfo.confirmResult=="1"){
            ly=this.auditingInfo.confirmInfo
        }
        var params={
            exchangeResultSnowID: this.auditingInfo.exchangeResultSnowID,
            confirmResult: this.auditingInfo.confirmResult,
            confirmInfo:ly,
        }
        this.$http.post("ExchangeResult/OilUserConfirm", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("审核已提交")
                    this.auditingModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true 
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }  
             });
    },

    showDetail(item){

        this.detailItem=item
        this.pdfSrc=""
        this.txtSrc=""
        this.cadSrc=""
        this.showtdt=false
        this.showzbt=false
        this.taskImgs=[]
        for(var i=0;i<item.Files.length;i++){
            var path=this.comjs.replaceFileSrc(item.Files[i].Path)
            if(item.Files[i].ImageType==2){
                this.pdfSrc=path
            }else if(item.Files[i].ImageType==3){
                this.cadSrc=path
            }else if(item.Files[i].ImageType==7){
                this.txtSrc=path
                this.showzbt=true
                this.resetMapDoms()
                this.getTxtFileContent(this.txtSrc,'mapboxC')
            }
        }
        this.getTaskInfo()
        this.getFallInfo(item.TaskSnowID)
        this.detailModal=true
    },
    getTaskInfo(){
        this.$http.post("Task/GetAllDataListWithZB", {snowID:this.detailItem.TaskSnowID})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    var d=response.data.DATA[0]
                    this.showtdt=true
                    setTimeout(()=>{
                        if(this.tianmap){
                            this.$refs.tmap.clearDraw()
                        }
                        var  c=[d.LON,d.LAT]
                        this.$refs.tmap.moveTo(c,16)
                        this.setTaskImgs(d.Files)
                    },500)
                    
                }
            })
    },
    setTaskImgs(list){
        for(var i=0;i<list.length;i++){
            var d=list[i]
            var path=this.comjs.replaceFileSrc(d.Path)
            this.taskImgs.push(path)
            this.$refs.tmap.appendTaskMarker(d)
        }
    },
    getFallInfo(taskid){
        this.fallDetail=null
        this.$http.post("LandLZ/GetDataList", {tasksnowid:taskid})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.fallDetail=response.data.DATA[0]
                }
            })
    },
    downloadTxtTemplate(){
        var url=window.location.host;
      window.open("http://"+url+"/statics/lib/txtTemplate.txt")
    },
    downloadExcelTemplate(){
        var url=window.location.host;
      window.open("http://"+url+"/statics/lib/excelTemplate.xlsx")
    },

    stopExport(){
        if(this.exportStatus){
                this.$confirm('确定停止本次的导出操作吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.exportStatus=false
                    this.exportTimer=new Date().getTime().toString()
                    this.fileExportingStr=''
                    this.canDo=true
                })
               
            }
    },
    exportExcelByZydw(){
        if(this.canDo && !this.exportStatus){
            this.exportTimer=new Date().getTime().toString()
            this.sendExportExcelByZydw(this.exportTimer)
            this.fileExportingStr="正在导出excel统计表请稍后，点击取消"
        }
    },
    sendExportExcelByZydw(timestr){
        if(timestr===this.exportTimer){
            this.canDo=false
            this.exportStatus=true
            var params={
                workUnitSnowIDList:[this.zydwid],
                ejdwList:[],
            }
            this.$download.post("ExportExcel/ExportExchangeResultList", params,{ responseType: 'blob',timeout:300000})
                .then((resJson) => {
                    if(this.exportStatus && timestr===this.exportTimer){
                        if (resJson) {
                            var namenew=this.getFileName("1")
                            let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                            if (window.navigator.msSaveOrOpenBlob) {
                                if (this.form.name) {
                                    window.navigator.msSaveBlob(blob, this.form.name + '.xlsx')
                                } else {
                                window.navigator.msSaveBlob(blob, namenew+".xlsx")
                                }
                            } else {
                                let downloadElement = document.createElement('a')
                                let href = window.URL.createObjectURL(blob) 
                                downloadElement.href = href
                                downloadElement.download = namenew+".xlsx"
                                document.body.appendChild(downloadElement)
                                let evt = document.createEvent('MouseEvents')
                                evt.initEvent('click', false, false)
                                downloadElement.dispatchEvent(evt)
                                document.body.removeChild(downloadElement)
                            }
                            this.$message.success('文件已导出!')
                        } else {
                            this.$message.error("数据导出失败")
                        }
                    }
                    this.exportStatus=false
                    this.canDo=true
                })
                .catch((error) => {
                    this.exportStatus=false
                    this.canDo=true
                    this.$message.success('文件已导出!')
                });
            }
    },
    exportPdfByZydw(){
        if(this.canDo && !this.exportStatus){
            this.$confirm('导出pdf时可能会因为文件数量过多导致导出时间过慢请您耐心等待，点击继续开始导出', '提示', {
                confirmButtonText: '继续',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.exportTimer=new Date().getTime().toString()
                this.sendExportPdfByZydw(this.exportTimer)
                this.fileExportingStr="正在导出pdf扫描件请稍后，点击取消"
            })
            
        }
    },
    sendExportPdfByZydw(timestr){
        if(timestr===this.exportTimer){
            this.canDo=false
            this.exportStatus=true
            var params={
                workUnitSnowIDList:[this.zydwid],
                ejdwList:[]
            }
            this.$download.post("ExportExcel/ExportResultFile", params,{ responseType: 'blob',timeout:300000})
                .then((resJson) => {
                    if(this.exportStatus && timestr===this.exportTimer){
                        if (resJson) {
                            var namenew=this.getFileName("2")
                            let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                            if (window.navigator.msSaveOrOpenBlob) {
                                if (this.form.name) {
                                    window.navigator.msSaveBlob(blob, this.form.name + '.zip')
                                } else {
                                window.navigator.msSaveBlob(blob, namenew+".zip")
                                }
                            } else {
                                let downloadElement = document.createElement('a')
                                let href = window.URL.createObjectURL(blob) 
                                downloadElement.href = href
                                downloadElement.download = namenew+".zip"
                                document.body.appendChild(downloadElement)
                                let evt = document.createEvent('MouseEvents')
                                evt.initEvent('click', false, false)
                                downloadElement.dispatchEvent(evt)
                                document.body.removeChild(downloadElement)
                            }
                            this.$message.success('文件已导出!')
                        } else {
                            this.$message.error("数据导出失败")
                        }
                    }
                    this.exportStatus=false
                    this.canDo=true
                })
                .catch((error) => {
                    this.exportStatus=false
                    this.canDo=true
                    this.$message.error("数据导出失败")
                });
            }
    },
    exportExcelByEjdw(){
        this.exportName="导出成果excel统计表"
        this.exportFileType="1"
        this.exportModal=true
    },
    exportPdfByEjdw(){
        this.exportName="导出成果pdf扫描件"
        this.exportFileType="2"
        this.exportModal=true
    },

    submitExport(){
        if(this.canDo){
            if(this.exportStatus){
                this.$confirm('确定停止本次的导出操作吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.exportStatus=false
                    this.exportTimer=new Date().getTime().toString()
                })
               
            }else{
                 if(this.checkEjdws.length===0){
                    this.$message.error("请至少选择一个二级单位")
                }else{
                    this.exportTimer=new Date().getTime().toString()
                    if(this.exportFileType=="1"){
                        this.sendExportExcelByEjdw(this.exportTimer)
                    }else {
                        this.sendExportPdfByEjdw(this.exportTimer)
                    }
                    
                }
            }
            
        }
    },
    sendExportExcelByEjdw(timestr){
        if(timestr===this.exportTimer){
            this.canDo=false
            this.exportStatus=true
            var params={
                ejdwList:this.checkEjdws,
                workUnitSnowIDList:[],
            }
            this.$download.post("ExportExcel/ExportExchangeResultList", params,{ responseType: 'blob',timeout:300000})
                .then((resJson) => {
                    if(this.exportStatus && timestr===this.exportTimer){
                        if (resJson) {
                            var namenew=this.getFileName("3")
                            let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                            if (window.navigator.msSaveOrOpenBlob) {
                                if (this.form.name) {
                                    window.navigator.msSaveBlob(blob, this.form.name + '.xlsx')
                                } else {
                                window.navigator.msSaveBlob(blob, namenew+".xlsx")
                                }
                            } else {
                                let downloadElement = document.createElement('a')
                                let href = window.URL.createObjectURL(blob) 
                                downloadElement.href = href
                                downloadElement.download = namenew+".xlsx"
                                document.body.appendChild(downloadElement)
                                let evt = document.createEvent('MouseEvents')
                                evt.initEvent('click', false, false)
                                downloadElement.dispatchEvent(evt)
                                document.body.removeChild(downloadElement)
                            }
                            this.$message.success('文件已导出!')
                        } else {
                            this.$message.error("数据导出失败")
                        }
                    }
                    this.exportStatus=false
                    this.canDo=true
                })
                .catch((error) => {
                    this.exportStatus=false
                    this.canDo=true
                    this.$message.success('文件已导出!')
                });
            }
    },
    sendExportPdfByEjdw(timestr){
        if(timestr===this.exportTimer){
            this.canDo=false
            this.exportStatus=true
            var params={
                ejdwList:this.checkEjdws,
                workUnitSnowIDList:[],
            }
            this.$download.post("ExportExcel/ExportResultFile", params,{ responseType: 'blob',timeout:300000})
                .then((resJson) => {
                    if(this.exportStatus && timestr===this.exportTimer){
                        if (resJson) {
                            var namenew=this.getFileName("4")
                            let blob = new Blob([resJson.data], { type: 'application/octet-stream' })
                            if (window.navigator.msSaveOrOpenBlob) {
                                if (this.form.name) {
                                    window.navigator.msSaveBlob(blob, this.form.name + '.zip')
                                } else {
                                window.navigator.msSaveBlob(blob, namenew+".zip")
                                }
                            } else {
                                let downloadElement = document.createElement('a')
                                let href = window.URL.createObjectURL(blob) 
                                downloadElement.href = href
                                downloadElement.download = namenew+".zip"
                                document.body.appendChild(downloadElement)
                                let evt = document.createEvent('MouseEvents')
                                evt.initEvent('click', false, false)
                                downloadElement.dispatchEvent(evt)
                                document.body.removeChild(downloadElement)
                            }
                            this.$message.success('文件已导出!')
                        } else {
                            this.$message.error("数据导出失败")
                        }
                    }
                    this.exportStatus=false
                    this.canDo=true
                })
                .catch((error) => {
                    this.exportStatus=false
                    this.canDo=true
                    this.$message.error("数据导出失败")
                });
            }
    },

    getFileName(nameType){
        var name="" 
        var fileType=""

        if(nameType=="1"){
            name=this.userName
            fileType="成果统计表"
        }else if(nameType=="2"){
            name=this.userName
            fileType="证书扫描件"
        }else if(nameType=="3"){
            for(var j=0;j<this.checkEjdws.length;j++){
                var dwname=this.checkEjdws[j]
                if(j==0){
                    name=dwname
                }else{
                    name+="_"+dwname
                }
            }
            fileType="成果统计表"
        }else if(nameType=="4"){
            for(var j=0;j<this.checkEjdws.length;j++){
                var dwname=this.checkEjdws[j]
                if(j==0){
                    name=dwname
                }else{
                    name+="_"+dwname
                }
            }
            fileType="证书扫描件"
        }
        
        var date=new Date()
        var m=date.getMonth() + 1
        var d = date.getDate()
        var y=date.getFullYear()
        if (m < 10) m = '0' + m
        if (d < 10) d = '0' + d
        var t=y.toString()+m.toString()+d.toString()
        name+="_"+t

    
        name+=fileType


        return name
    },
    checkExportUnits(){
        if(this.checkEjdws.length<this.ejdwList.length){
            this.checkEjdws=this.ejdwList
        }else{
            this.checkEjdws=[]
        } 
    }
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem {
    float: right;
}

.showFieldTtem {
    float: right;
    margin-right: 20px;
}

.itemOut {
    overflow: hidden;
    background-color: #F8F8F9;
    padding: 10px 20px 0 20px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.halfItem {
    width: 50%;
    float: left;
    margin-bottom: 25px !important;
}

.itemBox {
    overflow: hidden;
    width: 100%;
}

.itemTit {
    overflow: hidden;
}

.itemTit p {
    float: left;
}

.moreRow {
    background-color: #F8F8F9;
    overflow: hidden;
    padding: 12px;
}

.moreRow .moreRowTit {
    margin: 10px 0;
    font-size: 16px;
}

.moreRow .moreRowTable {
    width: 100%;
}

.detailBox {
    overflow: hidden;
}

.detailBox .left {
    width: 680px;
    overflow: auto;
    float: left;
}

.detailBox .right {
    width: 660px;
    overflow: hidden;
    float: left;
    margin-left: 20px;
}

.detailBox .blockTitle {
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    font-size: 16px;
    color: #409EFF;
    font-weight: bold;
}

.detailBox .blockCon {
    overflow: hidden;
}

.detailBox .strRow {
    font-size: 14px;
    color: #333333;
    overflow: hidden;
    width: 100%;
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.detailBox .strRowh {
    width: 50% !important;
}

.detailBox .olmap {
    width: 680px;
    height: 300px;
    overflow: hidden;
}

.detailBox .imgRow {
    overflow: hidden;
    margin-top: 10px;
}

.el-checkbox-group {
    line-height: 30px;
}
</style>