<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">任务列表</p>
        </div>
        <div class="tableBox" style="top:50px;">

            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入任务名称" class="iw"></el-input>
                <el-input v-model="landName" placeholder="请输入地块名称" class="iw"></el-input>
                <el-input v-model="userName" placeholder="请输入作业人员名称" class="iw"></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%">
                    <el-table-column prop="NAME" label="名称"></el-table-column>
                    <!-- <el-table-column prop="ZSBH" label="地块证书编号"></el-table-column> -->
                    <el-table-column prop="MJ_M" label="面积（亩）"></el-table-column>
                    <el-table-column prop="LandName" label="宗地项目名称"></el-table-column>
                    <el-table-column prop="STATUS" label="任务状态" width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS == 0">待分配至作业单位</span>
                            <span v-else-if="scope.row.STATUS == 1">待分配至作业人员</span>
                            <span v-else-if="scope.row.STATUS == 2">作业人员待提交</span>
                            <span v-else-if="scope.row.STATUS == 3">正常提交</span>
                            <span v-else-if="scope.row.STATUS == 4">异常标注</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="EJDW" label="二级单位"></el-table-column>
                    <el-table-column prop="GIVETIME" label="分配时间" width="180">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS != 0">{{ scope.row.GIVETIME }}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WorkUnitName" label="作业单位">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS != 0">{{ scope.row.WorkUnitName }}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="WorkUserName" label="作业人员" width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS != 0 && scope.row.STATUS != 1">{{ scope.row.WorkUserName }}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="LASTSUBMITTIME" label="最近一次提交时间" width="180">
                        <template slot-scope="scope">
                            <span v-if="scope.row.STATUS == 3 || scope.row.STATUS == 4">{{ scope.row.LASTSUBMITTIME }}</span>
                            <span v-else>/</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="showPost(scope.row)" type="text" size="small" style="color:#409EFF"
                                v-if="scope.row.IsHaveLZ && scope.row.IsHaveLZ == 1">落宗列表</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex"
                    :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="fallListTitle" :visible.sync="fallListModal" width="1300px" :close-on-click-modal="false"
            top="50px">
            <div style="overflow:hidden">
                <el-input v-model="sbdcdyh" placeholder="请输入不动产单元号" class="ics"></el-input>
                <el-select v-model="zdlx" placeholder="选择宗地类型" filterable clearable class="ics">
                    <el-option v-for="item in FzdlxList" :key="item.SnowID" :label="item.Name"
                        :value="item.SnowID"></el-option>
                </el-select>
                <el-cascader v-model="regions" :options="options" :props="cityPropsB" placeholder="请选择省市区" class="ics"
                    clearable filterable> </el-cascader>
                <el-select v-model="tjr" placeholder="选择提交人" filterable clearable class="ics" v-show="sysType == '2'">
                    <el-option v-for="item in tjrList" :key="item.SNOWID" :label="item.DISPLAYNAME"
                        :value="item.SNOWID"></el-option>
                </el-select>
                <el-select v-model="sfyz" placeholder="是否有证（批复）" filterable clearable class="ics">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                </el-select>
                <el-select v-model="mjsfyy" placeholder="面积是否一样" filterable clearable class="ics">
                    <el-option label="是" value="0"></el-option>
                    <el-option label="否" value="1"></el-option>
                </el-select>
                <el-button type="primary" @click="searchFallList">查询</el-button>

            </div>
            <el-table :data="fallList" stripe style="width: 100%" :height="fallTableHeight">
                <el-table-column prop="LZLandTypeName" label="宗地类型"></el-table-column>
                <el-table-column prop="REGION" label="行政区"></el-table-column>
                <el-table-column prop="BDCDYH" label="不动产单元号" width="150"></el-table-column>
                <el-table-column prop="ISHAVECERTIFICATE" label="有无证（批复）" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.ISHAVECERTIFICATE == 1">有</span>
                        <span v-else>无</span>
                    </template>
                </el-table-column>
                <el-table-column prop="OLDBH" label="原证书编号" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.OLDBH">{{ scope.row.OLDBH }}</span>
                        <span v-else>/</span>
                    </template>
                </el-table-column>
                <el-table-column prop="LZMJ" label="落宗面积"></el-table-column>
                <el-table-column prop="OLDMJ" label="原面积">
                    <template slot-scope="scope">
                        <span v-if="scope.row.OLDMJ">{{ scope.row.OLDMJ }}</span>
                        <span v-else></span>
                    </template>
                </el-table-column>
                <el-table-column prop="CHANGEINFO" label="面积变化原因" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.CHANGEINFO">{{ scope.row.CHANGEINFO }}</span>
                        <span v-else>/</span>
                    </template>
                </el-table-column>

                <el-table-column prop="WorkUnitName" label="作业单位"></el-table-column>
                <el-table-column prop="WorkUserName" label="提交人"></el-table-column>
                <el-table-column prop="UpdateTime" label="提交时间"></el-table-column>
                <el-table-column label="操作" width="120" v-if="sysType == '4'">
                    <template slot-scope="scope">
                        <el-button @click="addPost(scope.row)" type="text" size="small"
                            style="color:#67C23A">提交成果</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="total, prev, pager, next" :current-page="FPageIndex"
                :page-size="FPageSize" :total="FTotal" class="pg" @current-change="FPGChange"></el-pagination>
            <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="fruitModal" width="800px" :close-on-click-modal="false" top="20px">
            <el-form :model="fruitInfo" status-icon :rules="fruitrules" ref="fForm" label-width="220px"
                class="demo-uForm">
                <el-form-item label="宗地名称" prop="landName">
                    <el-input v-model="fruitInfo.landName" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="宗地类型" prop="landTypeSnowID">
                    <el-select v-model="fruitInfo.landTypeSnowID" placeholder="请选择宗地类型" filterable style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name"
                            :value="item.SnowID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地块现状" prop="landStatus">
                    <el-select v-model="fruitInfo.landStatus" placeholder="请选择地块现状" filterable style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option value="1" label="正常"></el-option>
                        <el-option value="2" label="侵占"></el-option>
                        <el-option value="3" label="耕地"></el-option>
                        <el-option value="4" label="无井"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级单位" prop="ejdw">
                    <el-select v-model="fruitInfo.ejdw" placeholder="请选择二级单位" filterable style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option v-for="item in ejdwList" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="使用单位">
                    <el-input v-model="fruitInfo.sydw" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="不动产证书编号" prop="zsbh">
                    <el-input v-model="fruitInfo.zsbh" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="权利人" prop="qlr">
                    <el-input v-model="fruitInfo.qlr" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="共有情况" prop="gyqk">
                    <el-select v-model="fruitInfo.gyqk" placeholder="请选择共有情况" filterable style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option v-for="item in dicgyqk" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="坐落" prop="zl">
                    <el-input v-model="fruitInfo.zl" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="不动产单元号" prop="bdcdyh">
                    <el-input v-model="fruitInfo.bdcdyh" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="权利类型" prop="qllx">
                    <el-select v-model="fruitInfo.qllx" placeholder="请选择权利类型" filterable allow-create style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option v-for="item in dicqllx" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="权利性质" prop="qlxz">
                    <el-select v-model="fruitInfo.qlxz" placeholder="请选择权利性质" filterable allow-create style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option v-for="item in dicqlxz" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用途" prop="yt">
                    <el-select v-model="fruitInfo.yt" placeholder="请选择用途" filterable allow-create style="width:100%;"
                        :disabled="canSubmitFruit">
                        <el-option v-for="item in dicyt" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="使用期限">
                    <el-input v-model="fruitInfo.syqx" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="权利其他状况">
                    <el-input v-model="fruitInfo.qlqtzk" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <el-form-item label="发证地区" prop="fzdws">
                    <el-cascader v-model="fruitInfo.fzdws" ref="cascaderAddr" :options="options" :props="cityProps"
                        placeholder="请选地区" style="width:100%" :disabled="canSubmitFruit"></el-cascader>
                </el-form-item>
                <el-form-item label="面积（平方米）" prop="mj">
                    <el-input v-model="fruitInfo.mj" autocomplete="off" :disabled="canSubmitFruit"></el-input>
                </el-form-item>
                <!--  -->
                <el-form-item label="地块范围坐标文件" prop="txt">
                    <div>
                        <el-upload :disabled="canSubmitFruit" style="display:inline-block;" class="avatar-uploader"
                            action="" :show-file-list="false" :http-request="uploadFunB"
                            :before-upload="beforeAvatarUploadB">
                            <div v-if="txtFileName != ''">
                                <p>{{ txtFileName }}　 </p>　

                            </div>
                            <el-button v-else type="primary" size="mini"
                                :disabled="canSubmitFruit">选择地块范围坐标txt文件</el-button>
                        </el-upload>
                        <p style="color:#409EFF;text-align:left;display:inline-block;margin-left:10px;">
                            坐标面积： <span v-if="txtFileName != ''">{{ txtArea }}㎡ </span> <span v-else>未选择</span>
                        </p>
                    </div>
                    <div>
                        <el-upload :disabled="canSubmitFruit" style="display:inline-block;" class="avatar-uploader"
                            action="" :show-file-list="false" :http-request="uploadFunE"
                            :before-upload="beforeAvatarUploadE">
                            <div v-if="excelFileName != ''">
                                <p>{{ excelFileName }}　 </p>　
                            </div>
                            <el-button v-else type="primary" size="mini"
                                :disabled="canSubmitFruit">选择excel文件转换成txt</el-button>
                        </el-upload>
                        <el-button v-if="excelFileName != ''" type="text" style="color:#E6A23C;"
                            @click="saveExcelToTxt()" :disabled="canSubmitFruit">点击保存{{ txtFileName }}到本地</el-button>
                    </div>
                    <el-button type="danger" @click="deleteFileB()" style="margin-left:0;" size="small"
                        :disabled="canSubmitFruit">清空所有坐标文件</el-button>
                </el-form-item>
                <el-form-item label="文件格式说明">
                    <p style="line-height: 20px;margin-top: 11px;color:#F56C6C;">1、地块范围文件必须为txt格式 2、必须为CGCS2000投影坐标
                        3、坐标点用J1(1为序号)开头，用逗号分割 4、如有多个面用Z隔开 5、可以通过excel转换成txt上传</p>
                    <p><span style="cursor: pointer;color:#E6A23C;" @click="downloadTxtTemplate">点击下载txt文件模板</span><span
                            style="cursor: pointer;color:#E6A23C;margin-left:20px"
                            @click="downloadExcelTemplate">点击下载excel文件模板</span></p>
                </el-form-item>
                <div v-show="showMap">
                    <div id="mapbox" style="width:100%;height:300px;background-color:#F8F8F8;"></div>
                    <div style="overflow: hidden;margin: 10px 0px;">
                        <el-button type="success" @click="reloadMapLayer" size="small"
                            style="float:right;">刷新当前图层</el-button>
                        <el-checkbox v-model="feaOI" @change="fraOIChange">坐标(txt)文件图形预览</el-checkbox>
                        <el-button type="primary" @click="toTxtFileCenter" size="small" style="margin-left: 20px;">
                            定位到坐标(txt)文件位置</el-button>
                    </div>
                    <p style="width: 100%;overflow: hidden;font-size: 12px;color:#F56C6C;">
                        提交完成后刷新当前图层查看（红色）切片是否正确显示，如果切片未显示或图形不对（切片有延迟，请多刷新几次图层）请联系开发人员。</p>
                </div>
                <el-form-item label="不动产权证书、宗地图描件上传" prop="smj">
                    <el-upload :disabled="canSubmitFruit" class="avatar-uploader" action="" :show-file-list="false"
                        :http-request="uploadFunC" :before-upload="beforeAvatarUploadC">
                        <span v-if="smjFileName != ''" style="color:#409EFF;">{{ smjFileName }}</span>
                        <el-button v-else type="primary" size="mini" :disabled="canSubmitFruit">选择文件</el-button>
                    </el-upload>
                    <el-button v-if="smjFileName != ''" type="danger" size="mini" @click="deleteFileC()"
                        :disabled="canSubmitFruit">取消选择</el-button>
                </el-form-item>
                <el-form-item label="CAD源文件上传">
                    <el-upload :disabled="canSubmitFruit" class="avatar-uploader" action="" :show-file-list="false"
                        :http-request="uploadFunD" :before-upload="beforeAvatarUploadD">
                        <span v-if="cadFileName != ''" style="color:#409EFF;">{{ cadFileName }}</span>
                        <el-button v-else type="primary" size="mini" :disabled="canSubmitFruit">选择文件</el-button>
                    </el-upload>
                    <el-button v-if="cadFileName != ''" type="danger" size="mini" @click="deleteFileD()"
                        :disabled="canSubmitFruit">取消选择</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitFruitForm()" :disabled="canSubmitFruit">提交</el-button>
                    <el-button @click="fruitModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script type="text/javascript" src="../../public/statics/js/proj4.js"></script>
<script>
var h = document.body.clientHeight
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import ImageWMS from "ol/source/ImageWMS";
import OSM from 'ol/source/OSM';
import Projection from 'ol/proj/Projection';
import {Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer ,Image as ImageLayer} from "ol/layer";
import WMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo';
import { Fill, Stroke, Circle, Style } from "ol/style";
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import {register} from 'ol/proj/proj4';
import * as olProj from 'ol/proj';
import * as XLSX from 'xlsx';
import * as turf from '@turf/turf';
export default {
  name: "Task",
  data() {
    var landName=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入宗地名称"));
      } else {
        callback();
      }
    };

    var landTypeSnowID=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择宗地类型"));
      } else {
        callback();
      }
    };

    var sydw=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入使用单位"));
      } else {
        callback();
      }
    };

    var landStatus = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择地块现状"));
      } else {
        callback();
      }
    };

    var ejdw = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择二级单位"));
      } else {
        callback();
      }
    };

    var qlr = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利人"));
      } else {
        callback();
      }
    };

    var gyqk = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入共有情况"));
      } else {
        callback();
      }
    };

    var zl = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入坐落"));
      } else {
        callback();
      }
    };

    var bdcdyh = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入不动产单元号"));
      } else {
        callback();
      }
    };

    var qllx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利类型"));
      } else {
        callback();
      }
    };

    var qlxz = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入权利性质"));
      } else {
        callback();
      }
    };

    var yt = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入用途"));
      } else {
        callback();
      }
    };
    
    var mJ_M=(rule, value, callback) => {
        if(value != "" && typeof value != 'number' && isNaN(value)){
            callback(new Error('面积只能是数字'));
        }else if(value<=0){
            callback(new Error('面积必须大于0'));
        }else {
          callback();
        }
    };
    var syqx = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入使用期限"));
      } else {
        callback();
      }
    };

    var fzdws = (rule, value, callback) => {
      if (value.length!=3) {
        callback(new Error("请选择发证地区"));
      } else {
        callback();
      }
    };

    var smj=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请上传不动产权证书、宗地图扫描件"));
      } else {
        callback();
      }
    };

    var txt=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请上传地块范围坐标文件"));
      } else {
        callback();
      }
    };

    var zsbh=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入土地证（不动产证）号"));
      } else {
        callback();
      }
    };
    return {
        sysType:"",

        zydwid:"",
        canDo:true,
        showPagination:false,
        name:"",
        userName:"",
        landName:"",
        postList:[],
        PageIndex:1,
        PageSize:10,
        Total:0,

        
        dicgyqk:[],
        dicqllx:[],
        dicqlxz:[],
        dicyt:[],
        


        doTypeStr:"",
        doType:"",
        ejdwList:[],
        fzdwList:[],
        fruitModal:false,
        fruitInfo:{},
        fruitrules: {
            landName: [{ validator: landName, trigger: "blur" }],
            landTypeSnowID: [{ validator: landTypeSnowID, trigger: "blur" }],
            landStatus: [{ validator: landStatus, trigger: "blur" }],
            ejdw: [{ validator: ejdw, trigger: "blur" }],
            sydw: [{ validator: sydw, trigger: "blur" }],
            zsbh: [{ validator: zsbh, trigger: "blur" }],
            qlr: [{ validator: qlr, trigger: "blur" }],
            gyqk: [{ validator: gyqk, trigger: "blur" }],
            zl: [{ validator: zl, trigger: "blur" }],
            bdcdyh: [{ validator: bdcdyh, trigger: "blur" }],
            qllx: [{ validator: qllx, trigger: "blur" }],
            qlxz: [{ validator: qlxz, trigger: "blur" }],
            yt: [{ validator: yt, trigger: "blur" }],
            mj: [{ validator: mJ_M, trigger: "blur" }],
            syqx: [{ validator: syqx, trigger: "blur" }],
            fzdws: [{ validator: fzdws, trigger: "blur" }],
            smj:[{ validator: smj, trigger: "blur" }],
            txt:[{ validator: txt, trigger: "blur" }],
        },
        zdlxList:[],


        txtArea:0,
        txtFile:null,
        txtFileSrc:"",
        txtFileId:"",
        txtFileName:"",
        chooseTxtFile:false,
        smjFile:null,
        smjFileSrc:"",
        smjFileId:"",
        smjFileName:"",
        chooseSmjFile:false,
        cadFile:null,
        cadFileSrc:"",
        cadFileId:"",
        cadFileName:"",
        chooseCadFile:false,


        excelFile:null,
        excelFileSrc:"",
        excelFileName:"",
        excelzbstr:"",


        fallDetail:null,

        options:[],
        cityProps:{
            value:'name',   
            label:'name',  
            children: 'children'   
        },
        cityPropsB:{
            value:'name',   
            label:'name',  
            children: 'children',
            checkStrictly:true,
        },

        taskInfo:null,
        fallTableHeight:0,
        sbdcdyh:"",
        tjr:"",
        tjrList:[],
        sfyz:"",
        mjsfyy:"",
        zdlx:"",
        regions:[],
        FPageIndex:1,
        FPageSize:10,
        FTotal:0,   
        fallList:[],
        fallListModal:false,
        fallListTitle:"",
        FzdlxList:[],

        drawSource:null,
        drawVector:null,
        map:null,
        view:null,
        showMap:false,
        wsmSource:null,
        feaOI:true,
        mapCenter:null,

        canSubmitFruit:false,
    };
  },
  mounted() {
    this.sysType=this.$store.state.sysType 
    this.fallTableHeight = h - 280
    this.options=this.comjs.citys
    this.loadItem=null
    this.getDisList()
    this.getZydwid()
    this.getZdlx()
    this.getFZdlx()
    this.getFzdwList()
    this.getEjdwList()
  },
  methods: {
    getDisList(){
        this.$http.post("Dic/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    var list=response.data.DATA
                    for(var i=0;i<list.length;i++){
                        var d=list[i]
                        if(d.Type==1){
                            this.dicgyqk.push(d.Name)
                        }else if(d.Type==2){
                            this.dicqllx.push(d.Name)
                        }else if(d.Type==3){
                            this.dicqlxz.push(d.Name)
                        }else if(d.Type==4){
                            this.dicyt.push(d.Name)
                        }
                    }
                }
            })
    },
    getFzdwList(){
        this.$http.post("ExchangeResult/GetDistrictList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.fzdwList=response.data.DATA
                }
            })
    },
    getEjdwList(){
        this.$http.get("Land/GetEJDWList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ejdwList=response.data.DATA
                }
            })
    },
    getZdlx(){
        this.canDo=false
        this.$http.post("LZLandType/GetDataList", {})
            .then((response) => {
                this.zdlxList=response.data.DATA
            })
    },
    getZdlxNameById(id){
        var name=""
        for(var i=0;i<this.zdlxList.length;i++){
            if(this.zdlxList[i].SnowID== id){
                name=this.zdlxList[i].Name
            }
        }
        return name
    },
    getZydwid(){
        this.canDo=false
        this.$http.post("ResultUser/GetDataList", {snowID:this.$store.state.snowid})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.zydwid=response.data.DATA[0].WorkUnitSnowID
                    this.getzzryList(this.zydwid)
                    this.canDo=true
                    this.getPostList()
                }else{
                    this.$confirm('作业单位获取失败，请重新登录', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                       this.logout()
                    }).catch(() => {
                        this.logout()
                    });
                    setTimeout(() => {
                        this.logout()
                    }, 10000);
                }
            })
    },
    getzzryList(unitid){
        this.$http.post("WorkUser/GetDataList", {parentSnowID:unitid})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.tjrList=response.data.DATA
                }
            })
    },
    getFZdlx(){
        this.$http.post("LZLandType/GetDataList", {})
            .then((response) => {
                this.FzdlxList=response.data.DATA
            })
    },
    //获取任务
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            name:this.name,
            stutas:"3",
            ISHAVELZ:"1",
            workUnitName:"",
            workUserName:this.userName,
            landName:this.landName,
            WorkUnitSnowID:this.zydwid,
            giveStartTime:"",
            giveEndTime:"",
            LastSubmitStartTime:"",
            LastSubmitEndTime:"",
            type:"2",
            landSnowID:"",
            EJDW:"",
            //HaveExchangeResult:"0",
        }
        this.$http.post("Task/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
        });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },


    toTxtFileCenter(){
        if(this.mapCenter){
            this.view.setCenter(this.mapCenter)
        }else{
            this.$message.error("请先上传坐标文件")
        }
    },
    fraOIChange(){
        this.drawVector.setVisible(this.feaOI)
    },
    reloadMapLayer(){
        if(this.map&&this.wsmSource){
            this.wsmSource.refresh();
            this.$message.success("刷新成功")
        }
    },

    loadMapDoms(arr){
        if(this.map){
            this.map.updateSize()
            this.drawSource.clear()
        }else{
            proj4.defs(
                "EPSG:4529",
                "+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=41500000 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs"
            )
            window.ol.proj.proj4.register(proj4);
            register(proj4)
            // var center_lonlat = [123.049,41.928];
            // var lonlat_3857 = olProj.fromLonLat(center_lonlat, olProj.get("EPSG:3857"));
            this.view=new View({
                center: [121.9939703484, 41.1335237089], 
                projection: 'EPSG:4326',
                zoom: 10, 
            })
            this.wsmSource=new ImageWMS({
                url: "https://file.17fbfb.com/?map=/etc/mapserver/wms.map&service=wms?",
                params: {
                    MAP:'/etc/mapserver/wms.map',
                    LAYERS:'zongdi',
                    //CRS:'epsp:4529',
                    //BBOX:'122,41,123.2,42',
                    transparent: "true"
                },
                serverType:'mapserver'
            })
            this.map = new Map({
                zIndex:1,
                target: "mapbox",
                layers: [
                    new ImageLayer({
						name:"地块", 
						source: this.wsmSource,
					}),
				],
                view: this.view,
            });
            this.map.on('singleclick', function(event) {
                const coordinate = event.coordinate; 
                console.log(coordinate)
            })
            this.map.updateSize()
            this.drawSource = new VectorSource({wrapX: false});
            this.drawVector = new VectorLayer({
                source: this.drawSource,
                zIndex:100,
                style: new Style({
                    fill: new Fill({
                        color: "rgba(64,158,255,0.2)"
                    }),
                    stroke: new Stroke({
                        color: "#409EFF",
                        width: 2
                    }),
                    image: new Circle({
                        radius: 5,
                        fill: new Fill({
                            color: "rgba(64,158,255,1)"
                        })
                    })
                }),
            });
            this.map.addLayer(this.drawVector)
        }
        var arrItem=this.transformXY(arr)
        
    
        if(arrItem.a.length==1){
            var areaGeom= new window.ol.geom.Polygon(arrItem.a)
            this.txtArea=areaGeom.getArea()
        }else{
            var independentPolygons=this.getIndependentPolygons(arrItem.a)
            var sarea=0
            for(var i=0;i<independentPolygons.length;i++){
                var inditem= independentPolygons[i]
                let indGeom=new window.ol.geom.Polygon(inditem)
                sarea+=indGeom.getArea()
            }
            this.txtArea=sarea
        }
        


        var newZbs=[]
        for(var z=0;z<arrItem.b.length;z++){
            var zbz=arrItem.b[z]
            var nzbz=[]
            for(var k=0;k<zbz.length;k++){
                let zbr=window.ol.proj.transform(zbz[k], 'EPSG:3857', 'EPSG:4326');
                nzbz.push(zbr)
            }
            newZbs.push(nzbz)
        }    

        var feaGeom= new Polygon(newZbs)
        var ft = new Feature({
            geometry: feaGeom,
        });
        this.drawSource.addFeature(ft)
        this.mapCenter=newZbs[0][0]
        this.view.setCenter(newZbs[0][0])
        this.view.setZoom(15)
    },








    getIndependentPolygons(arrlist){
        var polygonList=[]
        var outPolygon=turf.polygon([arrlist[0]])
        var onePolygon=[arrlist[0]]
        for(var i=1;i<arrlist.length;i++){
            var pitem=arrlist[i]
            var titem=turf.polygon([pitem])
            let f=turf.booleanContains(outPolygon,titem)
            if(f){
                onePolygon.push(pitem)
                if((i+1)==arrlist.length){
                    polygonList.push(onePolygon)
                }
            }else{
                polygonList.push(onePolygon)
                if((i+1)==arrlist.length){
                    polygonList.push([pitem])
                }else{
                    outPolygon=turf.polygon([pitem])
                    onePolygon=[pitem]
                }
            }
        }
        return polygonList
    },
    transformXY(olist){
        var arr={
            a:[],
            b:[],
        }
        for(var k=0;k<olist.length;k++){
            var list =olist[k]
            var l={a:[],b:[],}
            for(var i=0;i<list.length;i++){
                var arritem=[list[i][1],list[i][0]]
                l.a.push(arritem)
                var lonlat_3857 = olProj.transform(arritem, 'EPSG:4529','EPSG:3857');
                l.b.push(lonlat_3857)
            }
            arr.a.push(l.a)
            arr.b.push(l.b)
        }
        
        return arr
    },

    resetMapDoms(){
        this.showMap=false
        if(this.map){
            if(this.drawSource){
                this.drawSource.clear()
            }
        }
    },

    saveWmsDatas(info,snowid){
        if(this.doType=="1"){
            var fd = new FormData();
            fd.append("file", this.txtFile);
            this.$wmsUpload.post("gdata/shp/create/", fd)
            .then((response) => {
                    var oid=response.data.objectid
                    var paramStr=oid+"?snowid="+snowid+"&tdzh="+info.zsbh+"&zl="+info.zl+"&dh="+info.bdcdyh+"&syqlx="+info.qllx+"&fzmc="+info.qlr+"&syqlx="+info.qllx+"&fzmj="+info.mj+"&scmj="+info.txtMJ
                    var params={
                        objectid:parseInt(oid),
                        snowid:snowid,
                        tdzh:info.zsbh,
                        zl:info.zl,
                        dh:info.bdcdyh,
                        syqlx:info.qllx,
                        fzmc:info.qlr,
                        syqlx:info.qllx,
                        fzmj:parseFloat(info.mj),
                        scmj:parseFloat(info.txtMJ),
                    }
                    this.$wmsAxios.post("gdata/shp/update/"+paramStr, params)
                }) 
        }else if(this.doType=="2"){
            var params={
                snowid:info.SnowID,
                tdzh:info.zsbh,
                zl:info.zl,
                dh:info.bdcdyh,
                syqlx:info.qllx,
                fzmc:info.qlr,
                syqlx:info.qllx,
                fzmc:parseFloat(info.mj),
                scmj:parseFloat(info.txtMJ),
            }
            if(this.chooseTxtFile && this.txtFile){
                var fd = new FormData();
                fd.append("file", this.txtFile);
                //上传文件
                this.$wmsUpload.post("gdata/shp/create/", fd)
                .then((response) => {
                    var oid=parseInt(response.data.objectid)
                    params.objectid=oid
                    var paramStr=oid+"?snowid="+info.SnowID+"&tdzh="+info.zsbh+"&zl="+info.zl+"&dh="+info.bdcdyh+"&syqlx="+info.qllx+"&fzmc="+info.qlr+"&syqlx="+info.qllx+"&fzmj="+info.mj+"&scmj="+info.txtMJ
                    //更新当前数据
                    this.$wmsAxios.post("gdata/shp/update/"+paramStr, params)
                    //查询数据
                    this.$wmsAxios.get("gdata/shp/querysnowid/"+info.SnowID, {})
                    .then((response) => {
                        if( typeof response.data === 'string'){
                            var qd=JSON.parse(response.data)
                            var fs=qd.features
                            for(var i=0;i<fs.length;i++){
                                if(fs[i].properties.OBJECTID != oid && fs[i].properties.SNOWID && fs[i].properties.SNOWID!=""){
                                    var udata={
                                        objectid:fs[i].properties.OBJECTID,
                                        snowid:"",
                                    }
                                    //更新归档数据
                                    this.$wmsAxios.post("gdata/shp/update/"+fs[i].properties.OBJECTID+"?snowid=0", udata)
                                }
                            }
                        }
                        
                    })  

                })  
            }else{
                //查询数据
                this.$wmsAxios.get("gdata/shp/querysnowid/"+info.SnowID, {})
                .then((response) => {
                    if( typeof response.data === 'string'){
                        var qd=JSON.parse(response.data)
                        var fs=qd.features
                        var oidd=0
                        for(var i=0;i<fs.length;i++){
                            if(fs[i].properties.OBJECTID > oidd){
                                oidd=fs[i].properties.OBJECTID
                            }
                        }
                        if(oidd !=0){
                            params.objectid=oidd
                            var paramStr=oidd+"?snowid="+info.SnowID+"&tdzh="+info.zsbh+"&zl="+info.zl+"&dh="+info.bdcdyh+"&syqlx="+info.qllx+"&fzmc="+info.qlr+"&syqlx="+info.qllx+"&fzmj="+info.mj+"&scmj="+info.txtMJ
                            //更新数据
                            this.$wmsAxios.post("gdata/shp/update/"+paramStr, params)
                        }
                    }
                    
                })  
            }
        }
    },

    getTxtFileContent(src){
        this.$http.get("file/GetContent?fileURL="+src, {})
            .then((response) => {
               var arr=this.getZbByFileStr(response.data);
                if(arr.length>0){
                    this.showMap=true
                    setTimeout(()=>{
                        this.loadMapDoms(arr)
                    },500)
                }else{
                    this.$message.error('文件坐标格式有误，请重新上传')
                    this.txtFile=null
                    this.txtFileSrc=""
                    this.txtFileId=""
                    this.txtFileName=""
                    this.chooseTxtFile=false
                    this.txtArea=0
                    this.fruitInfo.txt=""
                }
            })
    },
    addPost(item){
        this.excelFile=null
        this.excelFileSrc=""
        this.excelFileName=""
        this.excelzbstr=""

        this.txtFile=null
        this.txtFileSrc=""
        this.txtFileId=""
        this.txtFileName=""
        this.chooseTxtFile=false
        this.txtArea=0
        
        this.resetMapDoms()

        this.smjFile=null
        this.smjFileSrc=""
        this.smjFileId=""
        this.smjFileName=""
        this.chooseSmjFile=false

        this.cadFile=null
        this.cadFileSrc=""
        this.cadFileId=""
        this.cadFileName=""
        this.chooseCadFile=false

        this.doTypeStr=item.LANDNAME+"成果汇交登记"

        this.$http.post("ExchangeResult/GetDataList", {LZSnowID:item.SnowID})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    var d=response.data.DATA[0]
                    var msgStr=""
                    if(d.SubmitUserSnowID == this.$store.state.snowid){
                        if(d.STATUS == 0 || d.STATUS == 4){
                            this.canSubmitFruit=false
                        }else{
                            msgStr='该成果已经提交并已审核无法继续提交或编辑，是否查看？'
                            this.canSubmitFruit=true
                        }
                       
                    }else{

                        msgStr='该落宗已被'+d.WorkUnitName+'/'+d.SubmitUserName+'提交过成果，是否查看？'
                        this.canSubmitFruit=true
                       
                    }
                    var  smj=""
                    var  txt=""
                    for(var i=0;i<d.Files.length;i++){
                        var path=this.comjs.replaceFileSrc(d.Files[i].Path)
                        if(d.Files[i].ImageType ==2){
                            smj="1"
                            this.smjFileName="不动产权证书、宗地图描件.pdf"
                            this.smjFileSrc=path
                            this.smjFileId=d.Files[i].SnowID
                        }else if(d.Files[i].ImageType ==3){
                            this.cadFileName="CAD源文件.dwg"
                            this.cadFileSrc=path
                            this.cadFileId=d.Files[i].SnowID
                        }else if(d.Files[i].ImageType ==7){
                            txt="1"
                            this.txtFileName="坐标范围文件.txt"
                            this.txtFileSrc=path
                            this.txtFileId=d.Files[i].SnowID
                            this.getTxtFileContent(path)
                        }
                    }
                    var fzdws=[]
                    if(d.FZDW.indexOf("/")!= -1){
                        fzdws=d.FZDW.split("/")
                    }
                    this.fruitInfo={
                        SnowID:d.SnowID,
                        lzSnowID:d.LZSnowID,
                        taskSnowID:d.TaskSnowID,
                        landName:d.LandName,
                        landTypeSnowID:d.LandTypeSnowID,
                        landSnowID:d.LandSnowID,
                        landStatus:d.LandStatus,
                        submitUserSnowID:this.$store.state.snowid,
                        ejdw:d.EJDW,
                        sydw:d.SYDW,
                        zsbh:d.ZSBH,
                        qlr:d.QLR,
                        gyqk:d.GYQK,
                        zl:d.ZL,
                        bdcdyh:d.BDCDYH,
                        qllx:d.QLLX,
                        qlxz:d.QLXZ,
                        yt:d.YT,
                        mj:d.MJ,
                        txtMJ:d.TxtMJ,
                        syqx:d.SYQX,
                        qlqtzk:d.QLQTZK,
                        fzdw:d.FZDW,
                        fzdws:fzdws,
                        smj:smj,
                        txt:txt,
                    }
                    this.doType="2"
                }else{
                    this.canSubmitFruit=false
                    var fzdws=item.REGION.split("/")
                    this.fruitInfo={
                        //landSnowID:item.LANDSNOWID,
                        lzSnowID:item.SnowID,
                        taskSnowID:this.taskInfo.SNOWID,
                        landName:item.LANDNAME,
                        landTypeSnowID:item.LZLANDTYPESNOWID,
                        landStatus:"",
                        submitUserSnowID:this.$store.state.snowid,
                        ejdw:item.EJDW,
                        sydw:"",
                        zsbh:"",
                        qlr:"",
                        gyqk:"",
                        zl:"",
                        bdcdyh:item.BDCDYH,
                        qllx:"",
                        qlxz:"",
                        yt:"",
                        mj:item.LZMJ,
                        syqx:"",
                        qlqtzk:"",
                        fzdws:fzdws,
                        fzdw:item.REGION,
                        smj:"",
                        txt:"",
                    }
                    this.doType="1"
                }
                if(this.canSubmitFruit){
                    this.$confirm(msgStr, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.fruitModal=true
                    })
                }else{
                    this.fruitModal=true
                }
                
            })
       
        
        
    },
    submitFruitForm() {
        if (this.canDo) {
            this.$refs.fForm.validate((valid) => {
                if (valid) {
                    delete  this.fruitInfo.smj
                    delete  this.fruitInfo.txt
                    if(this.fruitInfo.fzdws.length==3){
                        this.fruitInfo.fzdw=this.fruitInfo.fzdws[0]+"/"+this.fruitInfo.fzdws[1]+"/"+this.fruitInfo.fzdws[2]
                    }
                    this.fruitInfo.txtMJ=this.txtArea
                    if(this.txtArea<=0){
                        this.$message.error("点位坐标数据有误，请检查后再上传")
                    }else{
                        if(this.fruitInfo.mj != this.txtArea){
                            this.$confirm('坐标文件面积与证书面积不一致，是否继续提交？', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                if(this.doType=="1"){
                                    this.sendFAdd()
                                }else if(this.doType=="2"){
                                    this.sendFEdit()
                                }
                            
                            }).catch(() => {
                                //
                            });
                        }
                    }
                    
                    
                } else {
                    return false;
                }
            });
        }
    },
    sendFAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/InsertData", this.fruitInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    if(this.chooseTxtFile){
                        this.addImgB(response.data.DATA)
                        this.saveWmsDatas(this.fruitInfo,response.data.DATA)
                    }
                    if(this.chooseSmjFile){
                        this.addImgC(response.data.DATA)
                    }
                    if(this.chooseCadFile){
                        this.addImgD(response.data.DATA)
                    }
                    this.$confirm('是否留在本页面查看宗地图形，确保切片数据正确！', '提交完成', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.wsmSource.refresh();
                        this.canSubmitFruit=true;
                    }).catch(() => {
                        this.fruitModal=false
                    });
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                console.log("新增成果")
                console.log(error)
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    sendFEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ExchangeResult/UpdateData", this.fruitInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.searchFallListFun()
                    if(this.chooseTxtFile){
                        this.addImgB(this.fruitInfo.SnowID)
                    }
                    if(this.chooseSmjFile){
                        this.addImgC(this.fruitInfo.SnowID)
                    }
                    if(this.chooseCadFile){
                        this.addImgD(this.fruitInfo.SnowID)
                    }
                    this.saveWmsDatas(this.fruitInfo,this.fruitInfo.SnowID)

                    this.$confirm('是否留在本页面查看宗地图形，确保切片数据正确！', '提交完成', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.wsmSource.refresh();
                        this.canSubmitFruit=true;
                    }).catch(() => {
                        this.fruitModal=false
                    });
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                console.log("编辑成果")
                console.log(error)
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true   
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },





    beforeAvatarUploadC(file) {
        const isJPG = file.type === 'application/pdf';
        if (!isJPG) {
        this.$message.error('证书扫描件只能是 PDF  格式!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.smjFileSrc = _URL.createObjectURL(file);
            this.chooseSmjFile=true
            this.smjFile = file
            this.smjFileName=file.name
            this.fruitInfo.smj="1"
        }
        return isJPG;
    },
    uploadFunC(file){
        //console.log(file)
    },
    beforeAvatarUploadD(file) {
        var  isJPG =false;
        if (file.name.indexOf(".")== -1) {
            this.$message.error('CAD源文件件只能是 DWG  格式!');
        }else{
            if(file.name.split(".")[1] !="dwg" && file.name.split(".")[1] !="DWG"){
                this.$message.error('CAD源文件件只能是 DWG  格式!');
            }else{
                isJPG=true
                var _URL = window.URL || window.webkitURL;
                this.cadFileSrc = _URL.createObjectURL(file);
                this.chooseCadFile=true
                this.cadFile = file
                this.cadFileName=file.name
            }
        }
        return isJPG;
    },
    uploadFunD(file){
        //console.log(file)
    },
    beforeAvatarUploadB(file) {
        const isJPG = file.type === 'text/plain';
        if (!isJPG) {
        this.$message.error('坐标范围文件只能是 TXT  格式!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.txtFileSrc = _URL.createObjectURL(file);
            this.chooseTxtFile=true
            this.txtFile = file
            this.txtFileName=file.name
            this.fruitInfo.txt="1"
            this.excelFile=null
            this.excelFileSrc=""
            this.excelFileName=""
            this.excelzbstr=""
        }
        return isJPG;
    },
    uploadFunB(file){
        this.txtArea=0
        //计算面积
        this.readFile(file.file,(data)=>{
            var arr=this.getZbByFileStr(data);
            if(arr.length>0){
                this.showMap=true
                setTimeout(()=>{
                    this.loadMapDoms(arr)
                },500)
            }else{
                this.$message.error('文件坐标格式有误，请重新上传')
                this.txtFile=null
                this.txtFileSrc=""
                this.txtFileId=""
                this.txtFileName=""
                this.chooseTxtFile=false
                this.txtArea=0
                this.fruitInfo.txt=""
                this.excelFile=null
                this.excelFileSrc=""
                this.excelFileName=""
                this.excelzbstr=""
            }
        },()=>{
            this.$message.error('解析文件出错，请重新上传')
            this.txtFile=null
            this.txtFileSrc=""
            this.txtFileId=""
            this.txtFileName=""
            this.chooseTxtFile=false
            this.txtArea=0
            this.fruitInfo.txt=""
            this.excelFile=null
            this.excelFileSrc=""
            this.excelFileName=""
            this.excelzbstr=""
        })
    },
    readFile(file,sc,ec) {
        //支持chrome IE10
        if (window.FileReader) {
            var reader = new FileReader();
            reader.onload = function() {
                sc(this.result);
            };
            reader.readAsText(file);
        }
        //支持IE 7 8 9 10
        else if (typeof window.ActiveXObject != 'undefined'){
            var xmlDoc;
            xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
            xmlDoc.async = false;
            xmlDoc.load(input.value);
            sc(xmlDoc);
        }
        //支持FF
        else if (document.implementation && document.implementation.createDocument) {
            var xmlDoc;
            xmlDoc = document.implementation.createDocument("", "", null);
            xmlDoc.async = false;
            xmlDoc.load(input.value);
            sc(xmlDoc);
        } else {
            ec();
        }
    },
    getZbByFileStr(str){
        var arrs=[]
        var zbstr=str.replace(/\s+/g, '')
        var zbs=[]
        if(zbstr.indexOf("Z")!= -1){
            zbs= zbstr.split("Z")
        }else{
            zbs.push(zbstr)
        }

        for(var i=0;i<zbs.length;i++){
            var mian=zbs[i]
            var mianArr=[]
            if(mian.indexOf("J")!=-1){
                var dianArr=mian.split("J")
                for(var j=0;j<dianArr.length;j++){
                    var dian=dianArr[j]
                    var dianDatas=[]
                    if(dian!=""&&dian.indexOf(",")!=-1){
                        var diansZbs=dian.split(",")
                        for(var k=0;k<diansZbs.length;k++){
                            var dianZbItem=diansZbs[k]
                            if(dianZbItem.length>=7 || dianZbItem.indexOf(".")!=-1){
                                dianDatas.push(parseFloat(dianZbItem))
                            }
                        }
                        if(dianDatas.length==2){
                            mianArr.push(dianDatas)
                        }
                    } 
                }
            }
            if(mianArr.length>=4){
                if(mianArr[0][0] != mianArr[mianArr.length-1][0] || mianArr[0][1] != mianArr[mianArr.length-1][1]){
                    mianArr.push(mianArr[0])
                }
                arrs.push(mianArr)
            }
        }
        return arrs
    },


    beforeAvatarUploadE(file) {
        var  isJPG =false;
        if (file.name.indexOf(".")== -1) {
            this.$message.error('只能上传excel文件!');
        }else{
            if(file.name.split(".")[1] !="xlsx" && file.name.split(".")[1] !="xls"){
                this.$message.error('只能上传excel文件!');
            }else{
                isJPG=true
                var _URL = window.URL || window.webkitURL;
                this.excelFileSrc = _URL.createObjectURL(file);
                this.excelFile = file
                this.excelFileName=file.name
                this.excelzbstr=""
            }
        }
        return isJPG;
    },
    uploadFunE(file){
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            var excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            this.setZBStrByExcel(excelData)
        };
        reader.readAsArrayBuffer(file.file);
    },
    setZBStrByExcel(excelData){
        var str=""
        var jList=[]
        console.log(excelData)
        for(var i=0;i<excelData.length;i++){
            var d=excelData[i]
            if(d.length>=4 && typeof d[1] ==='string' && d[1].indexOf("J")!= -1){
                jList.push(d)
            }
        }
        var arrList=[]
        var arrItem=[]
        for(var j=0;j<jList.length;j++){
            var r=jList[j]
            var p=jList[j+1]
            if((p!=undefined &&  p[0] - r[0]) == 1 ){
                arrItem.push(r)
            }else if(p!=undefined && p[0] == r[0]){
                continue; 
            }else{
                arrItem.push(r)
                if(arrItem.length>1){
                    if((j+1) == jList.length-1){
                        arrItem.push(p)
                        arrList.push(arrItem)
                        break;
                    }else{
                        arrList.push(arrItem)
                    }
                    
                }
                arrItem=[]
            }
        }

        for(var k=0;k<arrList.length;k++){
            var m=arrList[k]
            if(k>0){
                str+="Z"+'\n'
            }
            for(var o=0;o<m.length;o++){
                var dian=m[o]
                str+=dian[1]+","+dian[dian.length-2]+","+dian[dian.length-1]+'\n'
            }
        }
        
        this.excelzbstr=str
        this.txtFileName=this.excelFileName.split(".")[0]+".txt"
        var blob = new Blob([str], { type: 'text/plain' });
        this.txtFile= new File([blob], this.txtFileName, {
            type: 'text/plain',
            lastModified: new Date().getTime(), 
        });
        
        this.fruitInfo.txt="1"
        this.chooseTxtFile=true
        var file={file:this.txtFile}
        this.uploadFunB(file)
    },
    saveExcelToTxt(){
        var blob = new Blob([this.excelzbstr], { type: 'text/plain' });
        var url = URL.createObjectURL(blob);
        var downloadLink = document.createElement("a");
        
        downloadLink.href = url;
        downloadLink.download = this.txtFileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
    },


    deleteFileB(){
        this.excelFile=null
        this.excelFileSrc=""
        this.excelFileName=""
        this.excelzbstr=""

        this.txtFile =null
        this.txtFileSrc=""
        this.txtFileName=""
        this.chooseTxtFile=false
        this.fruitInfo.txt=""
        this.txtArea=0
        this.showMap=false
        // if( this.txtFileId!=""){
        //     this.sendDelFile(this.txtFileId)
        // }
    },
    deleteFileC(){
        this.smjFile =null
        this.smjFileSrc=""
        this.smjFileName=""
        this.chooseSmjFile=false
        this.fruitInfo.smj=""

        // if( this.smjFileId!=""){
        //     this.sendDelFile(this.smjFileId)
        // }
    },
    deleteFileD(){
        this.cadFile =null
        this.cadFileSrc=""
        this.cadFileName=""
        this.chooseCadFile=false
        // if( this.cadFileId!=""){
        //     this.sendDelFile(this.cadFileId)
        // }
    },
    sendDelFile(id){
        this.$http.post("File/DeleteFile", {SnowID:id})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },

    addImgB(id){
        var fd = new FormData();
        fd.append("file", this.txtFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=7&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    //this.getPostList()
                }
            })
    },
    addImgC(id){
        var fd = new FormData();
        fd.append("file", this.smjFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=2&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    //this.getPostList()
                }
            })
    },
    addImgD(id){
        var fd = new FormData();
        fd.append("file", this.cadFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=3&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    //this.getPostList()
                }
            })
    },
    //显示落宗记录表
    showPost(item){
        this.taskInfo=item
        this.fallListTitle=item.NAME+"-落宗记录表"
        this.fallDetail=null
        this.fallList=[]
        this.FPageIndex=1
        this.FTotal=0
        this.sbdcdyh=""
        this.tjr=""
        this.sfyz=""
        this.mjsfyy=""
        this.zdlx=""
        this.searchFallListFun()
        this.fallListModal=true
    },
    searchFallList(){
        this.fallDetail=null
        this.fallList=[]
        this.FPageIndex=1
        this.FTotal=0
        this.searchFallListFun()
    },
    FPGChange(e){
        this.fallDetail=null
        this.fallList=[]
        this.FPageIndex=e
        this.FTotal=0
        this.searchFallListFun()
    },
    searchFallListFun(){
        this.showLoading("请稍后")
        var region=''
        if(this.regions && this.regions.length>0){
            for(var i=0;i<this.regions.length;i++){
                if(i==0){
                    region=this.regions[i]
                }else{
                    region=region+"/"+this.regions[i]
                }
            }
        }
        var params={
            tasksnowid:this.taskInfo.SNOWID,
            BDCDYH:this.sbdcdyh,
            pageIndex:this.FPageIndex,
            pageSize:this.FPageSize,
            region:region,
            submitworkunitsnowid:'',
            submitworkusersnowid:'',
            lzlandtypesnowid:this.zdlx,
            isHaveCertificate:this.sfyz,
            mjIsInconsistent:this.mjsfyy,
        }
        this.$http.post("LandLZ/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.fallList=response.data.DATA
                    this.FTotal=response.data.TOTALCOUNT
                } else {
                    this.fallList=[]
                    this.FTotal=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.fallList=[]
                this.FTotal=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    downloadTxtTemplate(){
        var url=window.location.host;
      window.open("http://"+url+"/statics/lib/txtTemplate.txt")
    },
    downloadExcelTemplate(){
        var url=window.location.host;
      window.open("http://"+url+"/statics/lib/excelTemplate.xlsx")
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped></style>