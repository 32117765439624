<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">汇交记录列表</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="name" placeholder="请输入宗地名称" class="iw"></el-input>
                <el-select v-model="zdlx" placeholder="选择宗地类型" filterable clearable class="iw">
                    <el-option v-for="item in zdlxList" :key="item.SnowID" :label="item.Name"
                        :value="item.SnowID"></el-option>
                </el-select>
                <el-input v-model="bdcdyh" placeholder="请输入不动产单元号" class="iw"></el-input>


                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-pagination background layout="total, prev, pager, next" :current-page="PageIndex"
                    :page-size="PageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" size="small">
                    <el-table-column prop="LandName" label="宗地名称"></el-table-column>
                    <el-table-column prop="LandTypeName" label="宗地类型" width="150"></el-table-column>
                    <el-table-column prop="EJDW" label="二级单位" width="100"></el-table-column>
                    <el-table-column prop="WorkUnitName" label="作业单位" width="100"></el-table-column>
                    <el-table-column prop="ZSBH" label="证书编号"></el-table-column>
                    <el-table-column prop="BDCDYH" label="不动产单元号"></el-table-column>
                    <el-table-column prop="MJ" label="证书面积（㎡）" width="100">
                        <template slot-scope="scope">
                            <span v-if="scope.row.MJ">{{ scope.row.MJ.toFixed(2) }}</span>
                            <span v-else></span>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" width="60">
                        <template slot-scope="scope">
                            <el-button @click="editPost(scope.row)" type="text" size="small"
                                style="color:#409EFF">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="conBox" style="position: relative;overflow: hidden;">
                    <el-button type="primary" size="mini" style="position: absolute;left: 52%;top: 10px;z-index: 666;"
                        @click="reloadMapLayer">重新加载地图</el-button>
                    <div id="mapbox" style="width:60%;height:370px;background-color:#F8F8F8;float:left;"></div>
                    <div style="width:38%;height:370px;background-color:#F8F8F8;float:right;">
                        <h2 style="margin:12px;">当前选中数据：</h2>
                        <div v-if="fruitInfo" style="padding:0 12px;">
                            <p style="font-size: 14px;">宗地名称：{{ fruitInfo.LandName }}</p>
                            <p style="font-size: 14px;">证书编号：{{ fruitInfo.ZSBH }}</p>
                            <p style="font-size: 14px;">不动产号：{{ fruitInfo.BDCDYH }}</p>
                            <p style="font-size: 14px;">证书面积：{{ fruitInfo.MJ }}</p>
                            <div v-if="fruitTxtInfo" style="margin-top: 12px;overflow: hidden;">
                                <h2 style="float:left;">当前坐标文件：</h2>
                                <!-- copyTxt -->
                                <el-button type="primary" @click="createTxtFile" size="small">复制txt(自动选择)</el-button>
                                <el-button type="primary" @click="openTxt" size="small">打开txt(手动保存)</el-button>
                                <div style="float:right;margin-top: 4px;">
                                    <span>文件图形显示影藏开关</span>
                                    <el-switch v-model="feaOI" active-color="#13ce66" inactive-color="#ff4949"
                                        @change="fraOIChange"></el-switch>
                                </div>
                                <p style="width: 100%;float:left;">当前文件面积：{{ txtArea }}</p>
                            </div>
                            <div style="margin-top:12px;">
                                <div style="overflow: hidden;">
                                    <h2 style="float:left;">选择坐标文件：</h2>
                                    <el-upload class="avatar-uploader" action="" :show-file-list="false"
                                        :http-request="uploadFunB" :before-upload="beforeAvatarUploadB"
                                        style="float:left;">

                                        <el-button type="primary" size="mini">点击选择文件</el-button>
                                    </el-upload>
                                </div>
                                <div v-if="chooseTxtFile" style="margin-top:12px;">
                                    <p style="margin-bottom: 10px;float:left;margin-top: 2px;color: #67C23A;">选择状态：已选择
                                    </p>
                                    <div style="float:right;margin-top: 4px;">
                                        <span>选择图形显示影藏开关</span>
                                        <el-switch v-model="feaOIB" active-color="#13ce66" inactive-color="#ff4949"
                                            @change="fraOIChangeB"></el-switch>
                                    </div>
                                    <el-button type="success" size="mini" @click="uploadTxt"
                                        style="float:left;margin-left: 10px;">提交更换</el-button>
                                    <el-button type="danger" size="mini" @click="clearTxt"
                                        style="float:left;margin-left: 10px;">清除选择</el-button>
                                    <p style="width: 100%;float:left;">选择文件面积：{{ choooseTxtArea }}</p>
                                    <p style="width: 100%;float:left;color: #F56C6C;font-size: 12px;">
                                        请核对好坐标位置和面积，确保上传的坐标txt与原来的一致后再进行更换,更换后点击地图上的“重新加载地图”，查看图形位置（图形切片处理略有延迟，请多刷新几次）
                                    </p>
                                </div>
                                <div v-else style="margin-top:12px;">
                                    选择状态：未选择
                                </div>
                            </div>

                        </div>
                        <div v-else style="margin:10px;">未选择，请在上方列表点击“查看”按钮选择</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript" src="../../public/statics/js/proj4.js"></script>
<script>
var h = document.body.clientHeight
import TianMap from "../../components/OpenLayers"

import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import ImageWMS from "ol/source/ImageWMS";
import OSM from 'ol/source/OSM';
import Projection from 'ol/proj/Projection';
import {Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer ,Image as ImageLayer} from "ol/layer";
import WMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo';
import { Fill, Stroke, Circle, Style } from "ol/style";
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import {register} from 'ol/proj/proj4';
import * as olProj from 'ol/proj';
import * as XLSX from 'xlsx';
import * as turf from '@turf/turf';
export default {
  name: "RegionManage",
  components:{
    TianMap,
  },
  data() {
    return {
        usersnowid:"",
        sysType:"",
        zydwid:"",
        userName:"",
        bdcdyh:"",
        zydwList:[],
        zydw:"",

        zdlxList:[],

        canDo:true,
        showPagination:false,
        ejdwstr:"",
        ejdwsel:[],
        ejdwSelList:[],
        fzdw:"",
        name:"",
        tjr:"",
        tjrList:[],
        cgzt:"",
        zdlx:"",

        postList:[],
        PageIndex:1,
        PageSize:5,
        Total:0,

        fruitInfo:null,
        fruitTxtInfo:null,
        fruitTxtStr:"",

        txtArea:0,
        txtFile:null,
        txtFileSrc:"",
        txtFileId:"",
        txtFileName:"",
        chooseTxtFile:false,
        choooseTxtArea:0,

       

        tianmap:null,
        olWidth:680,
        olHeight:300,

        fallDetail:{},

        
        feaOI:true,
        feaOIB:true,
        
        drawSource:null,
        drawVector:null,
        map:null,
        view:null,
        showMap:false,
        mapCenter:null,
        wsmSource:null,

        drawSourceB:null,
        drawVectorB:null,

    };
  },

  mounted() {
    this.options=this.comjs.citys
    this.pdfHeight = (h - 170)+"px"

    this.loadItem=null
    this.getZdlx()
    
    this.initMap()
    this.userName=this.$store.state.name
    this.sysType=this.$store.state.sysType  
    if(this.sysType=="2"){
        this.getzzdwA()
    }
    //this.getPostList()
  },
  methods: {
    getzzdwA(){
        this.$http.post("WorkUnit/GetDataList", {name:this.$store.state.name})
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    this.zydwid=response.data.DATA[0].SNOWID
                    this.getPostList()
                }else{

                    this.$confirm('作业单位获取失败，请重新登录', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                       this.logout()
                    }).catch(() => {
                        this.logout()
                    });
                    setTimeout(() => {
                        this.logout()
                    }, 10000);
                }
            })
    },
    getZdlx(){
        this.$http.post("LZLandType/GetDataList", {})
            .then((response) => {
                this.zdlxList=response.data.DATA
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.PageIndex,
            pageSize:this.PageSize,
            landName:this.name,
            landTypeSnowID:this.zdlx,
            workUnitSnowID:this.zydwid,
            LZBDCDYH:this.bdcdyh,

        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("ExchangeResult/GetDataList", params)
            .then((response) => {
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.postList=response.data.DATA
                    this.Total=response.data.TOTALCOUNT
                } else {
                    this.postList=[]
                    this.Total=0
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.PageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.PageIndex=e
        this.getPostList()
    },

    copyTxt(){
        const tempTextarea = document.createElement('textarea');
        tempTextarea.style.position = 'fixed';
        tempTextarea.style.left = '-9999px';
        tempTextarea.value = this.fruitTxtStr;
        document.body.appendChild(tempTextarea);
        tempTextarea.select();

        try {
            const successful = document.execCommand('copy');
            if (successful) {
                this.$message.success('复制成功');
            } else {
                this.$message.error('复制失败');
            }
        } catch (err) {
            this.$message.error('复制失败', err);
        } finally {
            document.body.removeChild(tempTextarea);
        }
    },

    openTxt(){
        window.open(this.fruitTxtInfo.Path)
    },

    fraOIChange(){
        this.drawVector.setVisible(this.feaOI)
    },
    fraOIChangeB(){
        this.drawVectorB.setVisible(this.feaOIB)
    },
    reloadMapLayer(){
        if(this.map&&this.wsmSource){
            this.wsmSource.refresh();
            this.$message.success("刷新成功")
        }
    },
    initMap(){
        proj4.defs(
            "EPSG:4529",
            "+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=41500000 +y_0=0 +ellps=GRS80 +units=m +no_defs +type=crs"
        )
        window.ol.proj.proj4.register(proj4);
        register(proj4);
        this.mapCenter=[121.9939703484, 41.1335237089]
        this.view=new View({
            center: [121.9939703484, 41.1335237089], 
            projection: 'EPSG:4326',
            zoom: 10, 
        })
        this.wsmSource=new ImageWMS({
            url: "https://file.17fbfb.com/?map=/etc/mapserver/wms.map&service=wms?",
            params: {
                MAP:'/etc/mapserver/wms.map',
                LAYERS:'zongdi',
                //CRS:'epsp:4529',
                //BBOX:'122,41,123.2,42',
                transparent: "true"
            },
            serverType:'mapserver'
        })
        this.map = new Map({
            zIndex:1,
            target: 'mapbox',
            layers: [
                new ImageLayer({
                    name:"地块", 
                    source: this.wsmSource,
                }),
            ],
            view: this.view,
        });
        this.drawSource = new VectorSource({wrapX: false});
        this.drawVector = new VectorLayer({
            source: this.drawSource,
            zIndex:100,
            style: new Style({
                fill: new Fill({
                    color: "rgba(64,158,255,0.2)"
                }),
                stroke: new Stroke({
                    color: "#409EFF",
                    width: 2
                }),
                image: new Circle({
                    radius: 5,
                    fill: new Fill({
                        color: "rgba(64,158,255,1)"
                    })
                })
            }),
        });
        this.map.addLayer(this.drawVector)
        this.drawSourceB = new VectorSource({wrapX: false});
        this.drawVectorB = new VectorLayer({
            source: this.drawSourceB,
            zIndex:101,
            style: new Style({
                fill: new Fill({
                    color: "rgba(230,162,60,0.2)"
                }),
                stroke: new Stroke({
                    color: "#ff9900",
                    width: 2
                }),
                image: new Circle({
                    radius: 5,
                    fill: new Fill({
                        color: "rgba(230,162,60,1)"
                    })
                })
            }),
        });
        this.map.addLayer(this.drawVectorB)
    },
    loadMapDoms(arr){
            var arrItem=this.transformXY(arr)
            if(arrItem.a.length==1){
                console.log(arrItem.a)
                var areaGeom= new window.ol.geom.Polygon(arrItem.a)
                this.txtArea=areaGeom.getArea()
            }else{
                var independentPolygons=this.getIndependentPolygons(arrItem.a)
                var sarea=0
                for(var i=0;i<independentPolygons.length;i++){
                    var inditem= independentPolygons[i]
                    let indGeom=new window.ol.geom.Polygon(inditem)
                    //console.log(indGeom.getArea())
                    sarea+=indGeom.getArea()
                }
                this.txtArea=sarea
            }
            var newZbs=[]
            for(var z=0;z<arrItem.b.length;z++){
                var zbz=arrItem.b[z]
                var nzbz=[]
                for(var k=0;k<zbz.length;k++){
                    let zbr=window.ol.proj.transform(zbz[k], 'EPSG:3857', 'EPSG:4326');
                    nzbz.push(zbr)
                }
                newZbs.push(nzbz)
            }
            var feaGeom= new Polygon(newZbs)
            var ft = new Feature({
                geometry: feaGeom,
            });
            this.drawSource.addFeature(ft)
            this.mapCenter=newZbs[0][0]
            this.view.setCenter(newZbs[0][0])
            this.view.setZoom(15)
    },
    getIndependentPolygons(arrlist){
        var polygonList=[]
        var outPolygon=turf.polygon([arrlist[0]])
        var onePolygon=[arrlist[0]]
        for(var i=1;i<arrlist.length;i++){
            var pitem=arrlist[i]
            var titem=turf.polygon([pitem])
            let f=turf.booleanContains(outPolygon,titem)
            if(f){
                onePolygon.push(pitem)
                if((i+1)==arrlist.length){
                    polygonList.push(onePolygon)
                }
            }else{
                polygonList.push(onePolygon)
                if((i+1)==arrlist.length){
                    polygonList.push([pitem])
                }else{
                    outPolygon=turf.polygon([pitem])
                    onePolygon=[pitem]
                }
            }
        }
        return polygonList
    },
    transformXY(olist){
        var arr={
            a:[],
            b:[],
        }
        for(var k=0;k<olist.length;k++){
            var list =olist[k]
            var l={a:[],b:[],}
            for(var i=0;i<list.length;i++){
                var arritem=[list[i][1],list[i][0]]
                l.a.push(arritem)
                var lonlat_3857 = olProj.transform(arritem, 'EPSG:4529','EPSG:3857');
                l.b.push(lonlat_3857)
            }
            arr.a.push(l.a)
            arr.b.push(l.b)
        }
        
        return arr
    },

    uploadTxt(){
        if(this.canDo){
            this.$confirm('确定更换坐标文件？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.addImgB()
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    clearTxt(){
        this.txtFile=null
        this.txtFileSrc=""
        this.txtFileId=""
        this.txtFileName=""
        this.chooseTxtFile=false
    },


    saveWmsDatas(info){
        var params={
            snowid:info.SnowID,
            tdzh:info.ZSBH,
            zl:info.ZL,
            dh:info.BDCDYH,
            syqlx:info.QLLX,
            fzmc:info.QLR,
            fzmj:parseFloat(info.MJ),
            scmj:parseFloat(info.TxtMJ),
        }
        if(this.chooseTxtFile && this.txtFile){
            var fd = new FormData();
            fd.append("file", this.txtFile);
            //上传文件
            this.$wmsUpload.post("gdata/shp/create/", fd)
            .then((response) => {
                var oid=parseInt(response.data.objectid)
                params.objectid=oid
                var paramStr=oid+"?snowid="+info.SnowID+"&tdzh="+info.ZSBH+"&zl="+info.ZL+"&dh="+info.BDCDYH+"&syqlx="+info.QLLX+"&fzmc="+info.QLR+"&fzmj="+info.MJ+"&scmj="+info.TxtMJ
                console.log(paramStr)
                //更新当前数据
                this.$wmsAxios.post("gdata/shp/update/"+paramStr, params)
                //查询数据
                this.$wmsAxios.get("gdata/shp/querysnowid/"+info.SnowID, {})
                .then((response) => {
                    if( typeof response.data === 'string'){
                        var qd=JSON.parse(response.data)
                        var fs=qd.features
                        for(var i=0;i<fs.length;i++){
                            if(fs[i].properties.OBJECTID != oid && fs[i].properties.SNOWID && fs[i].properties.SNOWID!=""){
                                var udata={
                                    objectid:fs[i].properties.OBJECTID,
                                    snowid:"",
                                }
                                //更新归档数据
                                this.$wmsAxios.post("gdata/shp/update/"+fs[i].properties.OBJECTID+"?snowid=0", udata)
                            }
                        }
                    }
                    
                })  

            }) 
            
            setTimeout(() => {
                this.hideLoading()
                this.$message.success("提交完成！")
                this.canDo=true
                this.reloadMapLayer()
            }, 2000);
        }
    },

    editPost(item){
        this.drawSource.clear()
        this.drawSourceB.clear()
        this.fruitInfo=item
        this.fruitTxtInfo=null
        this.fruitTxtStr=""

        this.txtFile=null
        this.txtFileSrc=""
        this.txtFileId=""
        this.txtFileName=""
        this.chooseTxtFile=false
        this.txtArea=0
        for(var i=0;i<item.Files.length;i++){
            if(item.Files[i].ImageType ==7){
               this.fruitTxtInfo=item.Files[i]
               this.getTxtFileContent(item.Files[i].Path)
            }
        }
    },

    getTxtFileContent(src){
        this.$http.get("file/GetContent?fileURL="+src, {})
            .then((response) => {
                this.fruitTxtStr=response.data
                var arr=this.getZbByFileStr(response.data);
                if(arr.length>0){
                    setTimeout(()=>{
                        this.loadMapDoms(arr)
                    },500)
                }else{
                    this.$message.error('文件坐标格式有误，请重新上传')
                    this.txtFile=null
                    this.txtFileSrc=""
                    this.txtFileId=""
                    this.txtFileName=""
                    this.chooseTxtFile=false
                    this.fruitInfo.txt=""
                }
            })
    },


    deleteWmsData(snowid){
        this.$wmsAxios.get("gdata/shp/querysnowid/"+snowid, {})
        .then((response) => {
            if( typeof response.data === 'string'){
                var qd=JSON.parse(response.data)
                var fs=qd.features
                for(var i=0;i<fs.length;i++){
                    if(fs[i].properties.SNOWID && fs[i].properties.SNOWID!=""){
                        var udata={
                            objectid:fs[i].properties.OBJECTID,
                            snowid:"",
                        }
                        //更新归档数据
                        this.$wmsAxios.post("gdata/shp/update/"+fs[i].properties.OBJECTID+"?snowid=0", udata)
                    }
                }
            }
            
        }) 
    },

    beforeAvatarUploadB(file) {
        console.log(file)
        const isJPG = file.type === 'text/plain';
        if (!isJPG) {
            this.$message.error('坐标范围文件只能是 TXT  格式!');
        }else{
            this.showLoading("正在检查文件...")
            var _URL = window.URL || window.webkitURL;
            this.txtFileSrc = _URL.createObjectURL(file);
            this.chooseTxtFile=true
            this.txtFile = file
            this.txtFileName=file.name
            this.fruitInfo.txt="1"
        }
        return isJPG;
    },
    loadTxtFileDoms(arr){
            var arrItem=this.transformXY(arr)
            if(arrItem.a.length==1){
                var areaGeom= new window.ol.geom.Polygon(arrItem.a)
                this.choooseTxtArea=areaGeom.getArea()
            }else{
                var independentPolygons=this.getIndependentPolygons(arrItem.a)
                var sarea=0
                for(var i=0;i<independentPolygons.length;i++){
                    var inditem= independentPolygons[i]
                    let indGeom=new window.ol.geom.Polygon(inditem)
                    //console.log(indGeom.getArea())
                    sarea+=indGeom.getArea()
                }
                this.choooseTxtArea=sarea
            }
            



            var newZbs=[]
            for(var z=0;z<arrItem.b.length;z++){
                var zbz=arrItem.b[z]
                var nzbz=[]
                for(var k=0;k<zbz.length;k++){
                    let zbr=window.ol.proj.transform(zbz[k], 'EPSG:3857', 'EPSG:4326');
                    nzbz.push(zbr)
                }
                newZbs.push(nzbz)
            }
            var feaGeom= new Polygon(newZbs)
            var ft = new Feature({
                geometry: feaGeom,
            });
        
            this.drawSourceB.addFeature(ft)
         
    },

    createTxtFile(){
        const fileName = 'output.txt';
        const fileType = 'text/plain';
        const blob = new Blob([this.fruitTxtStr], { type: fileType });
        const nfile = new File([blob], fileName, { type: fileType });
            var _URL = window.URL || window.webkitURL;
            this.txtFileSrc = _URL.createObjectURL(nfile);
            this.chooseTxtFile=true
            this.txtFile = nfile
            this.txtFileName=nfile.name
            this.fruitInfo.txt="1"
        const file={file:nfile}
        this.uploadFunB(file)
    },

    uploadFunB(file){
        this.readFile(file.file,(data)=>{
            var arr=this.getZbByFileStr(data);
            this.hideLoading()
            if(arr.length>0){
                this.$message.success('检查通过，可以进行提交')
                this.loadTxtFileDoms(arr)
            }else{
                this.$message.error('文件坐标格式有误，请重新上传')
                this.txtFile=null
                this.txtFileSrc=""
                this.txtFileId=""
                this.txtFileName=""
                this.chooseTxtFile=false
                this.fruitInfo.txt=""
            }
        },()=>{
            this.$message.error('解析文件出错，请重新上传')
            this.txtFile=null
            this.txtFileSrc=""
            this.txtFileId=""
            this.txtFileName=""
            this.chooseTxtFile=false
            this.fruitInfo.txt=""

            this.excelFile=null
            this.excelFileSrc=""
            this.excelFileName=""
            this.excelzbstr=""
        })
    },
    readFile(file,sc,ec) {
        //支持chrome IE10
        if (window.FileReader) {
            var reader = new FileReader();
            reader.onload = function() {
                sc(this.result);
            };
            reader.readAsText(file);
        }
        //支持IE 7 8 9 10
        else if (typeof window.ActiveXObject != 'undefined'){
            var xmlDoc;
            xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
            xmlDoc.async = false;
            xmlDoc.load(input.value);
            sc(xmlDoc);
        }
        //支持FF
        else if (document.implementation && document.implementation.createDocument) {
            var xmlDoc;
            xmlDoc = document.implementation.createDocument("", "", null);
            xmlDoc.async = false;
            xmlDoc.load(input.value);
            sc(xmlDoc);
        } else {
            ec();
        }
    },
    getZbByFileStr(str){
        var arrs=[]
        var zbstr=str.replace(/\s+/g, '')
        var zbs=[]
        if(zbstr.indexOf("Z")!= -1){
            zbs= zbstr.split("Z")
        }else{
            zbs.push(zbstr)
        }

        for(var i=0;i<zbs.length;i++){
            var mian=zbs[i]
            var mianArr=[]
            if(mian.indexOf("J")!=-1){
                var dianArr=mian.split("J")
                for(var j=0;j<dianArr.length;j++){
                    var dian=dianArr[j]
                    var dianDatas=[]
                    if(dian!=""&&dian.indexOf(",")!=-1){
                        var diansZbs=dian.split(",")
                        for(var k=0;k<diansZbs.length;k++){
                            var dianZbItem=diansZbs[k]
                            if(dianZbItem.length>=7 || dianZbItem.indexOf(".")!=-1){
                                dianDatas.push(parseFloat(dianZbItem))
                            }
                        }
                        if(dianDatas.length==2){
                            mianArr.push(dianDatas)
                        }
                    } 
                }
            }
            if(mianArr.length>=4){
                if(mianArr[0][0] != mianArr[mianArr.length-1][0] || mianArr[0][1] != mianArr[mianArr.length-1][1]){
                    mianArr.push(mianArr[0])
                }
                arrs.push(mianArr)
            }
        }
        return arrs
    },



    addImgB(){
        this.canDo=false
        this.showLoading("正在上传文件...")
        var fd = new FormData();
        fd.append("file", this.txtFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+this.fruitInfo.SnowID+"&FileType=7&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.saveWmsDatas(this.fruitInfo)
                }else{
                    this.$message.error("文件上传失败")
                }
            })
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>
.addProTtem {
    float: right;
}

.showFieldTtem {
    float: right;
    margin-right: 20px;
}

.itemOut {
    overflow: hidden;
    background-color: #F8F8F9;
    padding: 10px 20px 0 20px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.halfItem {
    width: 50%;
    float: left;
    margin-bottom: 25px !important;
}

.itemBox {
    overflow: hidden;
    width: 100%;
}

.itemTit {
    overflow: hidden;
}

.itemTit p {
    float: left;
}

.moreRow {
    background-color: #F8F8F9;
    overflow: hidden;
    padding: 12px;
}

.moreRow .moreRowTit {
    margin: 10px 0;
    font-size: 16px;
}

.moreRow .moreRowTable {
    width: 100%;
}

.detailBox {
    overflow: hidden;
}

.detailBox .left {
    width: 680px;
    overflow: auto;
    float: left;
}

.detailBox .right {
    width: 660px;
    overflow: hidden;
    float: left;
    margin-left: 20px;
}

.detailBox .blockTitle {
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    font-size: 16px;
    color: #409EFF;
    font-weight: bold;
}

.detailBox .blockCon {
    overflow: hidden;
}

.detailBox .strRow {
    font-size: 14px;
    color: #333333;
    overflow: hidden;
    width: 100%;
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.detailBox .strRowh {
    width: 50% !important;
}

.detailBox .olmap {
    width: 680px;
    height: 300px;
    overflow: hidden;
}

.detailBox .imgRow {
    overflow: hidden;
    margin-top: 10px;
}

.el-checkbox-group {
    line-height: 30px;
}
</style>